import { useSelector } from "react-redux";
import { Descriptions, Popover, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Paragraph } = Typography;
const infoList = [
  { label: "题目", value: "title" },
  { label: "作者", value: "author" },
  { label: "邮箱", value: "email" },
  { label: "收录会议或期刊", value: "journal" },
  { label: "编号", value: "number" },
  { label: "中文摘要", value: "abstract" },
  { label: "引文", value: "cite" },
  { label: "总字数", value: "words_count" },
];

function InfoIcon() {
  const { paperInfo = {} } = useSelector((state) => state.document);

  if (!paperInfo) return null;

  const content = (
    <Descriptions
      bordered
      column={1}
      labelStyle={{ width: 110, textAlign: "center" }}
      contentStyle={{ minWidth: 220 }}
    >
      {infoList.map((info, index) => {
        return (
          <Descriptions.Item
            key={`title-info-${index}`}
            label={info.label}
          >
            <Paragraph
              ellipsis={{
                rows: 3,
                expandable: false,
              }}
              title={paperInfo[info.value]}
            >
              {paperInfo[info.value]}
            </Paragraph>
          </Descriptions.Item>
        );
      })}
    </Descriptions>
  );

  return (
    <Popover
      content={content}
      arrowPointAtCenter={true}
      getPopupContainer={(node) => node}
      overlayInnerStyle={{ borderRadius: 12 }}
    >
      <InfoCircleOutlined />
    </Popover>
  );
}

export default InfoIcon;
