import { Fragment, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useRequest } from "ahooks";
import { Button, Input, Spin, Empty, Modal, message } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars-2";
import { isEmpty } from "lodash-es";
import { data as dataService } from "@/services/data";
import { feedback } from "@/utils/index";
import useScreenSize from "@/hooks/useScreenSize";
import ChatSessionItem from "./ChatSessionItem";
import ChatSessionStatistics from "./ChatSessionStatistics";
import ChatSessionBulk from "./ChatSessionBulk";
import cls from "classnames";
import styles from "./index.module.less";

function SessionList({ siderHandler }) {
  const dispatch = useDispatch();
  const { sessionId, latestSessionId } = useSelector((state) => state.chat);
  const { jwt, hadLogin } = useSelector((state) => state.token);
  const { middle } = useScreenSize();
  const [bulkStatus, setBulkStatus] = useState(false);
  const [bulkSelected, setBulkSelected] = useState([]);
  const [bulkIsAll, setBulkIsAll] = useState([]);
  const [bulkIIndeterminate, setBulkIIndeterminate] = useState([]);
  const [keyword, setKeyword] = useState();
  const [deletedIds, setDeletedIds] = useState([]);
  const [param, setParam] = useSearchParams();
  const urlId = param.get("id");
  const navigate = useNavigate();

  const afterRemoveHandler = ({ id }) => {
    setDeletedIds([...deletedIds, id]);
  };

  const afterRenameHandler = ({ id, title }) => {
    sessions?.data.forEach((item) => {
      if (item.id === id) {
        item.title = title;
      }
    });
  };

  useEffect(() => {
    setBulkSelected([]);
  }, [bulkStatus]);

  useEffect(() => {
    !jwt && mutate();
  }, [jwt]);

  useEffect(() => {
    urlId && getSessionInfoById();
    if (param.get("quickReply")) {
      dispatch.chat.update({
        quickReply: param.get("quickReply"),
        replyVersion: Date.now(),
      });
    }
  }, []);

  useEffect(() => {
    if (!bulkIIndeterminate) {
      setBulkSelected(bulkIsAll ? sessions?.data?.map((x) => x.id) : []);
    }
  }, [bulkIsAll, bulkIIndeterminate]);

  const {
    loading,
    data: sessions,
    mutate,
  } = useRequest(
    () =>
      dataService.getSessions({
        search: keyword,
        type: 0,
        from: param.get("from"),
      }),
    {
      ready: !!jwt,
      cacheKey: "cacheKey-chat",
      cacheTime: -1,
      // 刷新对话左侧列表的3种情况：
      // ① 绑定token/登陆等，用户信息发送变化
      // ② 列表搜索，用户输入的关键字发生变化，需要搜索过滤
      // ③ 最近一个会话ID变化（表示创建新会话了），需要刷新列表并显示最新会话在最前面
      refreshDeps: [jwt, keyword, latestSessionId],
      throttleWait: 500,
      onSuccess: (res) => {
        dispatch.user.getTokenInfo();
      },
      onError: (err) => {
        feedback(err.data);
      },
    }
  );

  // 路由携带ID获取详情
  const { run: getSessionInfoById } = useRequest(() => dataService.getSession(urlId), {
    manual: true,
    ready: !!urlId,
    onSuccess: (res) => {
      const { id, title, scenes } = res.data;
      dispatch.chat.update({
        sessionId: id,
        windowTitle: title,
        replyVersion: Date.now(),
        quickReply: scenes,
      });
    },
  });

  // 新会话处理路由参数
  useEffect(() => {
    if (!sessionId) return;
    navigate(`/chat?id=${sessionId}`, { replace: true });
  }, [sessionId]);

  const bulkRemoveHandler = useCallback(async () => {
    try {
      const res = await dataService.bulkRemoveSession({ ids: bulkSelected });
      if (res.status === "OK") {
        message.success("操作成功");
        setBulkSelected([]);
        setDeletedIds([...deletedIds, ...bulkSelected]);
        if (sessionId && bulkSelected.includes(sessionId)) {
          dispatch.chat.resetSession();
          navigate("/chat");
        }
      }
    } catch (err) {
      feedback(err.data);
    }
  }, [bulkSelected]);

  const setBulkByItemHandler = ({ id }) => {
    setBulkStatus(true);
    setTimeout(() => {
      setBulkSelected([id]);
    }, 100);
  };

  const searchHandler = useCallback(
    (value) => {
      setKeyword(value);
    },
    [setKeyword]
  );

  const newSessionHandler = useCallback(() => {
    navigate("/chat");
    dispatch.chat.resetSession();
    setKeyword();
    if (!middle) {
      siderHandler(false);
    }
  }, [setKeyword, dispatch, siderHandler]);

  const items = sessions?.data
    ?.filter((x) => !deletedIds.includes(x.id))
    ?.map((session) => (
      <ChatSessionItem
        key={session.id}
        session={session}
        currentSession={sessionId}
        siderHandler={siderHandler}
        afterRename={afterRenameHandler}
        afterRemove={afterRemoveHandler}
        bulkStatus={bulkStatus}
        bulkSelected={bulkSelected}
        updateBulkSelected={(selected) => setBulkSelected([...selected])}
        setBulkByItemHandler={setBulkByItemHandler}
      />
    ));

  return (
    <div className={cls(styles.sessionList, "sessionList")}>
      <div className="session-list-hd">
        <div className="search-and-create">
          <Input.Search
            placeholder={"搜索问题"}
            allowClear
            onChange={(e) => searchHandler(e.target.value)}
            value={keyword}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            trigger={"click"}
            onClick={newSessionHandler}
          >
            新建会话
          </Button>
        </div>
      </div>
      <div className="session-list-bd">
        {loading && !sessions ? (
          <div className="spin">
            <Spin indicator={<LoadingOutlined />} />
          </div>
        ) : (
          <Fragment>
            {bulkStatus ? (
              <ChatSessionBulk
                total={sessions?.data?.length || 0}
                bulkSelected={bulkSelected}
                updateBulkAllHandler={({ checked, indeterminate }) => {
                  setBulkIsAll(checked);
                  setBulkIIndeterminate(indeterminate);
                }}
                bulkCancelHandler={() => setBulkStatus(false)}
                bulkRemoveHandler={bulkRemoveHandler}
              />
            ) : (
              <ChatSessionStatistics
                count={sessions?.data?.length || 0}
                bulkStartHandler={() => setBulkStatus(true)}
              />
            )}
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: "calc(100% - 40px)" }}
            >
              {isEmpty(items) ? <Empty description={"请新建会话"} /> : items}
            </Scrollbars>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default SessionList;
