import { message } from "antd";

import { data as dataService } from "@/services";
import { feedback } from "@/utils/index";

export const chat = {
  state: {
    sessionId: undefined,
    // 最后一次会话id，用来检测更新并刷新左侧会话列表
    latestSessionId: undefined,
    version: Date.now(),
    windowTitle: undefined,
    replyVersion: Date.now(),
    quickReply: undefined,
    quickReplyMode: undefined,
    online: false,
    chatAtHome: false,
    pluginPlaceHolder: false,
    // 知网下载
    CNKI: { url: "", title: "", downloaded: false },
    // 万方下载
    Wanfang: { url: "", title: "", downloaded: false },
    // wiley下载
    Wiley: { url: "", title: "", downloaded: false },
    // 当前选中插件tab
    activeTabKey: "question-type-card-0",
    // 论文撰写status为5, 存储text内容传值到大纲编辑
    editOutlineData: undefined,
    // 插件placeholder内容
    placeHolderText: "",
  },
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    resetSession(state, payload) {
      return {
        sessionId: undefined,
        version: Date.now(),
        windowTitle: payload?.windowTitle || undefined,
        replyVersion: Date.now(),
        quickReply: undefined,
        quickReplyMode: undefined, // quickReply支持模式切换时，记录当前选中的模式
        online: false,
        chatAtHome: true,
        pluginPlaceHolder: false,
        CNKI: { url: "", title: "", downloaded: false },
        Wanfang: { url: "", title: "", downloaded: false },
        Wiley: { url: "", title: "", downloaded: false },
        editOutlineData: undefined,
        placeHolderText: "",
      };
    },
    updateLatestSessionId(state, payload) {
      return { ...state, latestSessionId: payload };
    },
    version(state, payload) {
      return { ...state, version: Date.now() };
    },
    updateCNKI(state, payload) {
      return { ...state, CNKI: payload };
    },
    updateWanfang(state, payload) {
      return { ...state, Wanfang: payload };
    },
    updateWiley(state, payload) {
      return { ...state, Wiley: payload };
    },
  },
  effects: (dispatch) => ({
    async addScholar(payload, rootState) {
      const { pdf_url, title, model, paper_url, db, info } = payload;
      try {
        message.info("开始处理，处理完毕后请到文献中查看分析结果。");
        const res = await dataService.addScholar({
          pdf_url,
          paper_url,
          title,
          model,
          db,
          info: JSON.stringify(info),
        });
        // eslint-disable-next-line no-inner-declarations
        async function poll() {
          const sessionRes = await dataService.getSession(res.data.id);
          if (window.location.pathname === "/chat") {
            if (sessionRes.data.status === 0 || sessionRes.data.status === 1) {
              setTimeout(poll, 1000);
            } else if (sessionRes.data.status === 2 || sessionRes.data.status === 4) {
              message.success(`${res.data.title}处理完成`);
              dispatch.user.getTokenInfo();
            } else if (sessionRes.data.status === 3) {
              feedback(sessionRes.data.content || "请检查文档是否有文本内容或被加密或字数超过10w或余额不足");
            }
          } else {
            dispatch.document.version();
            !!sessionRes.data.id &&
              dispatch.document.update({
                sessionId: sessionRes.data.id,
                file: null,
                windowTitle: res.data.title,
              });
          }
        }

        message.success(`${title}处理完毕,请到文献中查看分析结果`);
        // 开始轮询
        poll();
      } catch (err) {
        feedback(err.data);
      } finally {
        //上传结束
      }
      return false;
    },
  }),
};
