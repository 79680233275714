import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bubble } from "@chatui/core";
import { Checkbox, Image, Space, Spin } from "antd";
import { useToggle } from "ahooks";
import BubbleToolbar from "../BubbleToolbar";
import { setBubbleBgColor, setBubbleSelectable } from "@/utils/index";
import failedImage from "@/assets/failedImage.png";
import styles from "./index.module.less";

function ImageBubble({ text, createdAt, finished, setComposerText, messageId, position, isLast }) {
  const containRef = useRef();
  const dispatch = useDispatch();
  const { checking, checkedList } = useSelector((state) => state.user);
  const [isMarkdownFormat, { toggle: markdownFormatToggle }] = useToggle(true);
  const [url, setUrl] = useState("");
  const [alt, setAlt] = useState("");
  const [_text, setText] = useState("");
  const [errCount, setErrCount] = useState(0);
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    const url_match = text.match(/\((.*?)\)/);
    const text_match = text.match(/(.*)\n\s*!\[.*\]\(/);
    const alt_match = text.match(/\[(.*?)\]/);
    if (url_match) setUrl(url_match[1]);
    if (alt_match) setAlt(alt_match[1]);
    if (text_match) setText(text_match[1]);
  }, [text]);

  const clickHandler = useCallback(() => {
    if (checking) {
      const isChecked = checkedList.includes(messageId);
      const checkable = setBubbleBgColor(messageId, !isChecked);
      if (!checkable) return;
      setBubbleSelectable(false);
      if (isChecked) {
        dispatch.user.update({
          checkedList: checkedList.filter((item) => item !== messageId),
        });
      } else dispatch.user.update({ checkedList: [...checkedList, messageId] });
    }
  }, [checkedList]);

  const onLoaded = useCallback(() => {
    errCount < 2 ? setStatus("loaded") : null;
  }, [status, errCount]);

  const onError = useCallback(
    (event) => {
      setErrCount(errCount + 1);
      setStatus("error");
    },
    [status, errCount]
  );

  return (
    <div className={styles.bubbleLeftWrapper}>
      <div
        className="bubble-container"
        ref={containRef}
      >
        <div onClick={clickHandler}>
          <Bubble className={`bubble-${position}-${messageId} Bubble text image-bubble ${status}`}>
            <div>{_text}</div>
            <Image
              placeholder={<Spin />}
              alt={alt}
              src={url}
              width={200}
              onLoad={onLoaded}
              onError={onError}
              fallback={failedImage}
              lazy="lazy"
              preview={status !== "error"}
            />
            <div style={{ opacity: 0.5 }}>{`【图片-${alt}】`}</div>
          </Bubble>
          {checking && position === "left" && (
            <div className="bubble-checkbox-wrapper">
              <Checkbox checked={checkedList.includes(messageId)} />
            </div>
          )}
        </div>
        {!checking && finished && (
          <BubbleToolbar
            text={text}
            createdAt={createdAt}
            setComposerText={setComposerText}
            messageId={messageId}
            format={isMarkdownFormat}
            formatHandler={markdownFormatToggle}
            position={position}
            isLast={isLast}
            isImage={true}
          />
        )}
      </div>
    </div>
  );
}

export default ImageBubble;
