export const download = (
  <svg
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
    className="download"
  >
    <path
      d="M384 448V128h256v320h192l-320 320-320-320h192z m-192 384h640v64H192v-64z"
      fill="currentColor"
    ></path>
  </svg>
);

export const edit = (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M358.165868 554.624796c-0.533143 0.680499-1.066285 1.391696-1.303692 2.251274l-41.104163 150.700257c-2.400676 8.772804 0.059352 18.226107 6.550183 24.892947 4.860704 4.742001 11.261485 7.350408 18.077727 7.350408 2.252297 0 4.504594-0.267083 6.727215-0.860601l149.630902-40.808428c0.23843 0 0.357134 0.207731 0.534166 0.207731 1.718131 0 3.408633-0.62217 4.683672-1.927909l400.113747-400.054395c11.883655-11.897981 18.404162-28.109198 18.404162-45.74281 0-19.989263-8.476045-39.963177-23.324218-54.767348l-37.786605-37.844933c-14.81645-14.848173-34.822087-23.338544-54.797024-23.338544-17.631566 0-33.842783 6.520507-45.75816 18.388812L358.758362 553.232077C358.344946 553.615816 358.462626 554.179658 358.165868 554.624796M862.924953 257.19778l-39.742143 39.71349-64.428382-65.451688 39.180348-39.179324c6.193049-6.222725 18.194384-5.318122 25.308409 1.822508l37.813211 37.845956c3.943822 3.941775 6.195096 9.18622 6.195096 14.372336C867.223862 250.574942 865.710392 254.42769 862.924953 257.19778M429.322487 560.907896l288.712541-288.728914 64.459081 65.49569L494.314711 625.838721 429.322487 560.907896zM376.718409 677.970032l20.863167-76.580143 55.656601 55.657624L376.718409 677.970032z"
      fill="#231F20"
      p-id="4297"
    ></path>
    <path
      d="M888.265084 415.735539c-15.144932 0-27.562752 12.313443-27.620058 27.665083l0 372.98283c0 19.559475-15.885805 35.444257-35.475979 35.444257L194.220958 851.827709c-19.559475 0-35.504632-15.883759-35.504632-35.444257L158.716326 207.602222c0-19.575848 15.945157-35.474956 35.504632-35.474956l406.367171 0c15.231913 0 27.592428-12.371772 27.592428-27.606755 0-15.202237-12.360516-27.590382-27.592428-27.590382L190.013123 116.930129c-47.684022 0-86.49291 38.779212-86.49291 86.49291L103.520213 820.59231c0 47.713698 38.808888 86.47756 86.49291 86.47756l639.334083 0c47.715745 0 86.509283-38.763862 86.509283-86.47756L915.856489 443.222567C915.794068 428.048983 903.408993 415.735539 888.265084 415.735539"
      fill="#231F20"
      p-id="4298"
    ></path>
  </svg>
);

export const stop = (
  <svg
    viewBox="0 0 1052 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="4327"
  >
    <path
      d="M1035.70446 377.837189A513.856306 513.856306 0 0 0 664.308054 16.650297a529.40722 529.40722 0 0 0-505.539948 137.92985 496.412237 496.412237 0 0 0-141.98661 491.814575 513.044954 513.044954 0 0 0 371.396406 361.051668 529.40722 529.40722 0 0 0 505.539947-137.929851 496.412237 496.412237 0 0 0 141.986611-491.611737z m-167.27375 419.198566l-210.27541-204.46072-48.883961 47.32887 210.275409 204.257882a459.766168 459.766168 0 0 1-611.759455-22.717858 429.408079 429.408079 0 0 1-23.461597-594.991512l196.955713 191.343862 49.019187-47.734547L233.412496 179.258772a459.766168 459.766168 0 0 1 611.759455 22.920696 429.610917 429.610917 0 0 1 23.664435 594.991512z"
      p-id="4328"
    ></path>
    <path
      d="M773.502519 312.252898l-84.177777-75.523355a47.32887 47.32887 0 0 0-73.089298 3.78631c-0.946577 0.946577-213.520818 235.292098-292.965707 324.540825a210.613473 210.613473 0 0 0-48.275448 76.67277v1.28464c-0.743739 15.956591-2.974958 96.618508 18.999161 116.361409a62.203658 62.203658 0 0 0 28.397322 12.778795 149.829681 149.829681 0 0 0 31.034217 2.839732 179.17358 179.17358 0 0 0 60.851404-9.803838 263.148519 263.148519 0 0 0 70.452404-55.645229l292.965707-324.540824a47.32887 47.32887 0 0 0-4.191985-72.751235zM419.347344 620.093393a17.106006 17.106006 0 0 1-2.434057-2.636895 35.496653 35.496653 0 0 0-9.939062-8.248746l-40.567603-21.636055 6.761267-7.166943 4.327211-4.732887c49.627701-55.645229 111.425683-124.204478 201.485762-223.121817l91.141881 81.74372-201.485762 223.121817c-1.487479 1.690317-3.380634 3.78631-5.273788 6.220366s-3.380634 4.124373-5.273788 6.220366l-25.963266-38.336385a33.806336 33.806336 0 0 0-9.398162-9.195323 24.5434 24.5434 0 0 0-3.380633-2.231218z m6.017527 84.583452c-0.946577 1.284641-2.231218 2.636894-3.583471 4.327211s-5.81469 7.166943-7.707845 8.992485l-3.921535 1.149416c-18.390647 5.611852-67.612672 20.283802-84.78629 5.81469a365.91978 365.91978 0 0 1-2.636894-90.803819 77.146059 77.146059 0 0 1 6.761267-6.761267 63.555912 63.555912 0 0 0 5.07095-4.665274l44.556751 23.664435a239.484084 239.484084 0 0 1 17.849745 15.956591z m313.858023-354.087562a20.283802 20.283802 0 0 1-6.761267 13.522534l-31.575118 34.955751-91.141882-81.74372 27.788809-30.831378 0.405676-0.405676 2.974957-3.380634c10.88564-11.088478 15.618527-11.291316 27.045069-1.690317l63.150235 56.591807a20.283802 20.283802 0 0 1 8.248746 12.981633z"
      p-id="4329"
    ></path>
  </svg>
);
