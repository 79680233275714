import styles from "./index.module.less";
import { Fragment, useState, useEffect, useCallback } from "react";
import ScrollToIcons from "@/icons/ScrollToIcons";

const ScrollToBottom = ({ messagesRef }) => {
  const [showButton, setShowButton] = useState(true);
  const scrollHandler = (e) => {
    const target = document.querySelector(".PullToRefresh");
    if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  };

  useEffect(() => {
    const target = document.querySelector(".PullToRefresh");
    target.addEventListener("scroll", scrollHandler);

    return () => {
      target.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const toBottomHandler = useCallback(() => {
    messagesRef.current?.scrollToEnd({ animated: true, force: true });
  });

  return (
    <Fragment>
      {showButton ? (
        <div
          className={styles.ScrollToBottom}
          onClick={toBottomHandler}
        >
          <div className="scroll-to-bottom-content">
            <ScrollToIcons type="down" />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default ScrollToBottom;
