import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import Chat from "@chatui/core";
import cls from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { flatten, isEmpty } from "lodash-es";
import { Alert, Button, Space, message, Tooltip, Avatar, BackTop } from "antd";
import { useRequest } from "ahooks";
import "katex/dist/katex.min.css";
import "@chatui/core/es/styles/index.less";
import "@/themes/index.less";
import Marquee from "react-fast-marquee";
import { feedback, enableComposer, onChatScroll } from "@/utils/index";
import { MESSAGE_TYPES } from "@/constants/message";
import { MODEL_ICONS, getModelName } from "@/constants/model";
import { data as dataService } from "@/services/data";
import useScreenSize from "@/hooks/useScreenSize";
import MessageContentRender from "@/components/common/MessageContentRender";
import GptComposer from "@/components/common/GptComposer";
import EvidenceSelectMenu from "@/components/common/AdditionalConfig/EvidenceSelectMenu";
import ScrollToBottom from "@/components/common/ScrollToBottom";
import ScrollToTop from "@/components/common/ScrollToTop";
import styles from "./index.module.less";
import ScrollToIcons from "@/icons/ScrollToIcons";

function ChatBot({ messages, resetList, sendHandler }) {
  const messagesRef = useRef();
  const composerRef = useRef();
  const { middle } = useScreenSize();
  const dispatch = useDispatch();

  const { sessionId, version, replyVersion, quickReply, chatAtHome, pluginPlaceHolder, placeHolderText } = useSelector(
    (state) => state.chat
  );
  const { model, avatar = "/user.png" } = useSelector((state) => state.token);
  const { checking, checkedList } = useSelector((state) => state.user);
  const [inputText, setInputText] = useState("");

  useEffect(() => {
    composerRef.current?.setText("");
    enableComposer();
    setTimeout(() => {
      if (sessionId) messagesRef.current?.scrollToEnd({ animated: true, force: true });
    }, 1000);
  }, [replyVersion]);

  useEffect(() => {
    if (sessionId) {
      if (isEmpty(messages))
        resetList([
          {
            _id: `${Date.now()}-loading`,
            id: `${Date.now()}-loading`,
            type: "normal-message",
            content: {
              message_type: "message-loading",
            },
          },
        ]);
    }
    if (quickReply) {
      resetList([
        {
          _id: `${Date.now()}-answer`,
          id: `${Date.now()}-answer`,
          type: "normal-message",
          content: {
            message_type: "plugin-placeholder",
            placeholder_type: quickReply,
          },
        },
      ]);
    } else if (!quickReply && !sessionId) {
      resetList([
        {
          _id: `${Date.now()}-answer`,
          id: `${Date.now()}-answer`,
          type: "normal-message",
          content: {
            message_type: "guess-you",
          },
        },
      ]);
    }
  }, [replyVersion]);

  const { loading } = useRequest(() => dataService.getSession(sessionId), {
    ready: !!sessionId,
    refreshDeps: [sessionId, version, model, avatar],
    onSuccess: (res) => {
      if (res.status === "OK") {
        const { messages: msgs } = res.data;
        const results = msgs.map((msg, index) => {
          let messageType, _question, config;
          _question = msg.question;
          config = msg.config;
          messageType = msg.config === null ? "text" : "file";
          const type = [2, 3, 4, 5].includes(msg.status) ? "normal-message" : "resolve-message";
          return [
            {
              _id: `${msg.id}-question`,
              id: `${msg.id}-question`,
              type: "normal-message",
              content: {
                createdAt: msg.created_at,
                text: _question,
                config: config,
                messageId: msg.id,
                message_type: messageType,
                isLast: index === msgs.length - 1,
                question_type: msg.question_type,
              },
              user: {
                avatar: avatar,
              },
              position: "right",
            },
            {
              _id: `${msg.id}-answer`,
              id: `${msg.id}-answer`,
              type,
              content: {
                createdAt: msg.modified_at,
                messageId: msg.id,
                status: msg.status,
                text: msg.answer,
                message_type: MESSAGE_TYPES[msg.answer_type],
                isLast: index === msgs.length - 1,
                question_type: msg.question_type,
                model: msg.model,
              },
              user: { avatar: MODEL_ICONS[msg.model] },
              position: "left",
            },
          ];
        });
        resetList([...flatten(results)]);
        dispatch.chat.update({
          windowTitle: res.data?.title,
        });
      } else {
        feedback(res.data);
      }
    },
    onError: (err) => {
      if (err.code === 404) {
        resetList([]);
      } else {
        feedback(err.data);
      }
    },
  });

  const deleteHandler = useCallback(async () => {
    try {
      await dataService.removeAnswers({ ids: checkedList });
      message.success("删除成功！");
      dispatch.user.resetCheck();
      dispatch.chat.version();
    } catch (err) {
      feedback(err.data);
    }
  }, [checkedList]);

  useEffect(() => {
    const chatFooter = document.querySelector(".ChatFooter");
    chatFooter.setAttribute("style", "padding: 0");
  }, []);

  return (
    <Fragment>
      {checking && (
        <Alert
          type="info"
          banner
          message={
            <Marquee
              pauseOnHover
              gradient={false}
            >
              点击 删除 按钮即可删除选中的问答，点击 取消 按钮退出批量删除。
            </Marquee>
          }
          closeIcon={<></>}
          action={
            <Space>
              <Button
                size="small"
                onClick={() => {
                  dispatch.user.resetCheck();
                }}
              >
                取消
              </Button>
              <Button
                size="small"
                disabled={isEmpty(checkedList)}
                danger
                type="primary"
                onClick={deleteHandler}
              >
                删除
              </Button>
            </Space>
          }
          closable
        />
      )}
      <div
        className={cls(styles.chatBot, {
          chatBotChecking: checking,
          chatBotCheckingPadding: checking,
          "guess-you": chatAtHome,
          "plugin-placeholder": pluginPlaceHolder,
        })}
      >
        <Chat
          onScroll={(e) => onChatScroll(e, loading)}
          locale="zh-CN"
          locales={{ Composer: { send: "发送" } }}
          wideBreakpoint="768px"
          composerRef={composerRef}
          messagesRef={messagesRef}
          messages={messages}
          Composer={GptComposer}
          placeholder={
            middle
              ? quickReply
                ? placeHolderText || "请输入提问内容，Shift+Enter换行"
                : "请输入提问内容，Shift+Enter换行，支持网络链接或网络图片"
              : "请输入提问内容"
          }
          onInputChange={(value) => {
            setInputText(value);
          }}
          text={inputText}
          renderMessageContent={(msg) => {
            return (
              <>
                {msg.position === "left" && (
                  <Tooltip
                    title={getModelName(msg.content.model)}
                    placement="topLeft"
                    arrowPointAtCenter
                  >
                    <Space size={4}>
                      <Avatar
                        size={38}
                        style={{ margin: "0 10px 0 10px" }}
                        src={MODEL_ICONS[msg.content.model]}
                      />
                    </Space>
                  </Tooltip>
                )}
                <MessageContentRender
                  msg={msg}
                  setText={composerRef.current?.setText}
                  showQuestionType={false}
                />
              </>
            );
          }}
          onSend={sendHandler}
        />
      </div>
      {sessionId && <ScrollToTop messagesRef={messagesRef} />}
      {sessionId && <ScrollToBottom messagesRef={messagesRef} />}
      {quickReply === "循证问答" && <EvidenceSelectMenu />}
    </Fragment>
  );
}

export default ChatBot;
