import * as CustomIcon from "./svg-icons";
const CardIconOptions = {
  论文润色: "lunwenrunse",
  翻译: "fanyi",
  英译汉: "fanyi",
  论文降重: "zhongwenjiangchong",
  中文校对和润色: "zhongwenjiaodui",
  论文作家: "lunwenzuojia",
  论文撰写: "lunwenzhuanxie",
  在线搜索: "zaixiansousuo",
  名人访谈: "mingrenfangtan",
  数学计算: "shuxuejisuan",
  易经占卜: "yijing",
  生成PPT: "ppt",
  语音转文字: "yuyinzhuanwenzi",
  文字转语音: "yuyinzhuanwenzi",
  旅游: "lvyou",
  网页分析: "wangyefenxi",
  动画: "donghua",
  画图: "huatu",
  OCR图文识别: "ocr",
  图片解释: "tupianjieshi",
  数据分析: "shujufenxi",
  文本数据分析: "wenbenshujufenxi",
  OFFICE密码破解: "mimapojie",
  知识百科: "zhishibaike",
  长文撰写: "changwenzhuanxie",
  生成音乐: "shengchengyinyue",
  arXiv检索: "arxiv",
  scholar论文检索: "googlescholar",
  PubMed检索: "pubmed",
  知网检索: "zhiwang",
  万方检索: "wanfang",
  Wiley检索: "wiley",
  置顶: "zhiding",
  取消置顶: "quxiaozhiding",
  去背景: "qubeijing",
  卡通化: "katonghua",
  科学数据检索: "kexue",
  文献综述: "wenxianzongshu",
  学术问答: "lunwenku",
  股市分析: "gushifenxi",
  论文智能推荐: "zhinengtuijian",
  GitHub问答: "github",
  GitHub搜索: "githubsousuo",
  漫画: "manhua",
  找图: "zhaotu",
  优惠券: "youhuijuan",
  学术历史: "xueshulishi",
  学术脉络: "xueshumailuo",
  全文检索: "quanwenjiansuo",
  整理表格: "zhenglibiaoge",
  学术热点: "xueshuredian",
  文章优化: "wenzhangyouhua",
  科研: "keyanzhuli",
  论文: "lunwenjiansuo",
  工具: "gongju",
  图像: "tuxiang",
  数据: "shujuchuli",
  图表数字化: "shuzihua",
  生成大纲: "shengchengdagang",
  开题报告: "kaitibaogao",
  文章读写: "wenzhangduxie",
  文章重组: "wenzhangchongzu",
  文章配图: "wenzhangpeitu",
  代码重现: "daimachongxian",
  图生论文: "tushenglunwen",
  默认消息: "morenxiaoxi",
  绘制流程图: "flowchart",
  批处理: "bulkprocess",
  思维导图: "mindmap",
  循证问答: "xunzhengwenda",
};

export const isIconExist = ({ type }) => {
  return CardIconOptions[type] && CustomIcon[CardIconOptions[type]];
};

export default function PluginCardIcons({ type }) {
  const typeEn = CardIconOptions[type];
  if (!typeEn) {
    return CustomIcon["moren"];
  }

  return CustomIcon[typeEn];
}
