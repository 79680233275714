import { useSelector } from "react-redux";
import cls from "classnames";
import { useClickAway } from "ahooks";
import useScreenSize from "@/hooks/useScreenSize";
import CopyRight from "@/components/common/CopyRight";
import Sider from "@/components/common/Sider";
import TopMessage from "@/components/common/TopMessage";
import WindowHeader from "@/components/common/WindowHeader";
import SessionList from "../SessionList";
import ChatWindow from "../ChatWindow";
import Noticebar from "./NoticeBar";
import styles from "./index.module.less";
import { useEffect } from "react";

function WindowBody({ showSider, siderHandler }) {
  useClickAway(() => {
    siderHandler(false);
  }, [
    document.querySelector(".absolute"),
    document.querySelector(".anticon-menu-unfold"),
    document.querySelector(".head-left"),
  ]);

  const { quickReply } = useSelector((state) => state.chat);
  const { chatPromptList, chatTutorUrlList, chatFaqUrlList } = useSelector((state) => state.app);
  const { messageList } = useSelector((state) => state.user);

  return (
    <div className={styles.windowBd}>
      <div className="window-body-container">
        <Sider
          onHandleClick={() => siderHandler(!showSider)}
          showHandle={true}
          showSider={showSider}
          className={cls({
            absolute: true,
            "full-width": showSider,
            "zero-width": !showSider,
          })}
          children={<SessionList siderHandler={siderHandler} />}
        />
        <div
          className={cls("window-body-inner", {
            "full-width": !showSider,
            "zero-width": showSider,
          })}
          style={{
            paddingLeft: showSider ? "300px" : 0,
          }}
          onWheel={(e) => {
            const pullToRefresh = document.querySelector(".PullToRefresh");
            const messageList = document.querySelector(".MessageList");
            const chatFooter = document.querySelector(".ChatFooter");
            if (messageList?.contains(e.target)) return;
            if (chatFooter?.contains(e.target)) return;
            pullToRefresh.scrollTop += e.deltaY;
          }}
        >
          <WindowHeader
            showSider={showSider}
            siderHandler={siderHandler}
          />
          {messageList.length > 0 && <Noticebar messageList={messageList} />}
          {quickReply && (
            <TopMessage
              quickReply={quickReply}
              prompts={chatPromptList}
              tutorUrls={chatTutorUrlList}
              faqUrls={chatFaqUrlList}
            />
          )}
          <ChatWindow />
          {/* <CopyRight /> */}
        </div>
      </div>
    </div>
  );
}

export default WindowBody;
