import { Fragment } from "react";
import { Tooltip, Button } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
import LeftBottomIcons from "@/icons/LeftBottomIcons";

function ShareButton() {
  return (
    <Fragment>
      <Tooltip
        title="分享"
        placement="right"
      >
        <a href="/console/affiliate">
          <div className="leftMenuBottom-Btn share-button">
            <LeftBottomIcons type="share" />
          </div>
        </a>
      </Tooltip>
    </Fragment>
  );
}

export default ShareButton;
