import { useSelector } from "react-redux";
import PubmedCard from "./PubmedCard";
import { List, ListItem } from "@chatui/core";
import TextBubble from "../Bubble/TextBubble";
import BubbleToolbar from "../BubbleToolbar";
import styles from "./index.module.less";

function PubmedBubble({ text, status, finished, createdAt, setComposerText, messageId, isLast }) {
  const { checking } = useSelector((state) => state.user);

  try {
    const itemList = JSON.parse(text);
    return (
      <List className={styles.pubmedBubble}>
        <ListItem
          className="result-reply"
          content={`以下是为您找到的 ${itemList.length} 篇论文，如需分析请使用学术问答插件`}
        />
        {itemList.map((item, index) => {
          return (
            <ListItem
              key={`listitem-${index}`}
              children={
                <PubmedCard
                  props={item}
                  index={index + 1}
                />
              }
            />
          );
        })}
        {!checking && finished && (
          <BubbleToolbar
            text={text}
            createdAt={createdAt}
            setComposerText={setComposerText}
            messageId={messageId}
            isLast={isLast}
            position={"left"}
            isScholar={true}
          />
        )}
      </List>
    );
  } catch (error) {
    return (
      <TextBubble
        text={text}
        status={status}
        finished={finished}
        createdAt={createdAt}
        setComposerText={setComposerText}
        messageId={messageId}
      />
    );
  }
}

export default PubmedBubble;
