import { memo, useEffect, useState } from "react";
import { Bubble } from "@chatui/core";
import { prepayment } from "@/utils/index";
import styles from "./index.module.less";
import { useSelector } from "react-redux";
import AuthModal from "@/components/common/WindowHeader/WindowToolbar/AuthModal";

function TimesErrorBubble({ error }) {
  const { hadOpenId } = useSelector((state) => state.token);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    // 先判断是否登录
    if (!hadOpenId) {
      // 如果未登录，则跳转到登录页面
      setOpen(true);
    } else {
      // 如果已登录，则执行付费操作
      prepayment(error);
    }
  }, []);

  return (
    <>
      <div className={styles.bubbleLeftWrapper}>
        <div className="bubble-container">
          <Bubble>
            <div
              className="error-info"
              dangerouslySetInnerHTML={{
                __html: error?.replace("会员", "<a href='/console/vip' replace='true'>会员</a>"),
              }}
            />
          </Bubble>
        </div>
      </div>
      <AuthModal
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

export default memo(TimesErrorBubble);
