import { useCallback, useState } from "react";
import useScreenSize from "@/hooks/useScreenSize";
import { Button, Tooltip } from "antd";
import { useMatch } from "react-router-dom";
import { FileSearchOutlined } from "@ant-design/icons";
import EvidenceModal from "./EvidenceModal";

function EvidenceButton({ quickReply }) {
  const { middle } = useScreenSize();
  const matchAtChat = useMatch("/chat");
  const [open, setOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const inputHandler = useCallback(() => {
    setOpen(true);
  }, []);

  const component = (
    <Tooltip
      placement="right"
      title={"输入PubMed检索策略，如未输入则系统根据输入的问题自动生成"}
      open={openTooltip}
    >
      {middle ? (
        <Button
          type="text"
          className="Btn Btn--primary input-tools-btn"
          size="small"
          onClick={inputHandler}
          onMouseEnter={() => setOpenTooltip(true)}
          onMouseLeave={() => setOpenTooltip(false)}
        >
          检索策略
        </Button>
      ) : (
        <Button
          size="large"
          type="text"
          icon={<FileSearchOutlined style={{ color: "var(--brand-1)" }} />}
          onClick={inputHandler}
          onMouseEnter={() => setOpenTooltip(true)}
          onMouseLeave={() => setOpenTooltip(false)}
        ></Button>
      )}
      <EvidenceModal
        open={open}
        setOpen={setOpen}
      ></EvidenceModal>
    </Tooltip>
  );

  if (matchAtChat) {
    switch (quickReply) {
      case "循证问答":
        return component;
    }
  }
}

export default EvidenceButton;
