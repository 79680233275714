import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton, Typography, List, Tooltip, Button, Tabs } from "antd";
import { LoadingOutlined, SendOutlined } from "@ant-design/icons";
import { isEmpty, groupBy } from "lodash-es";
import { useRequest } from "ahooks";
import { data as dataService } from "@/services";
import cls from "classnames";
import PluginCardIcons from "@/icons/PluginCardIcons/index";
import { feedback } from "@/utils/index";
import useScreenSize from "@/hooks/useScreenSize";
import styles from "./index.module.less";

const TopCard = ({ data, onClick, middle }) => {
  const dispatch = useDispatch();
  const [activeItem, setActiVeItem] = useState([]);

  const { run: untopping, loading } = useRequest((params) => dataService.untoppingTypeCard({ prompt: params.id }), {
    manual: true,
    debounceWait: 500,
    onSuccess: () => {
      dispatch.app.update({
        chatPromptVersion: Date.now(),
      });
    },
    onError: (err) => feedback(err.data || "操作失败"),
    onFinally: (params, data) => {
      setActiVeItem((activeItem) => activeItem.filter((item) => item.id === params[0].id));
    },
  });

  return (
    <div className={styles.questionTypeCard}>
      {!middle && <div style={{ marginTop: 12 }} />}
      <List
        itemLayout="vertical"
        size="large"
        split={false}
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            key={item.short_name}
            onClick={() => onClick(item)}
          >
            <List.Item.Meta
              title={
                <div
                  className="question-type-card-title"
                  title={item.short_name}
                >
                  <div className="my-icon">
                    <PluginCardIcons type={item.short_name} />
                  </div>
                  <div className="short_name">{item.short_name}</div>
                </div>
              }
              description={
                <Typography.Paragraph
                  type="secondary"
                  ellipsis={{ rows: 4 }}
                  title={item.description}
                >
                  {item.description}
                </Typography.Paragraph>
              }
            />
            <div className="card-actions-cancel">
              <Tooltip
                title="取消置顶"
                getPopupContainer={(node) => document.body}
                arrowPointAtCenter
                placement="left"
                mouseEnterDelay={0.8}
                mouseLeaveDelay={0}
              >
                <Button
                  type="text"
                  icon={
                    loading || activeItem.includes(item.id) ? (
                      <LoadingOutlined />
                    ) : (
                      <PluginCardIcons type={`取消置顶`} />
                    )
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiVeItem((activeItem) => [...activeItem, item.id]);
                    untopping(item);
                  }}
                />
              </Tooltip>
            </div>
            <div className="footer">
              进入
              <SendOutlined />
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

const QuestionTypeCard = ({ data, topData, onClick }) => {
  const dispatch = useDispatch();
  const [activeItem, setActiVeItem] = useState([]);

  const { run: topping, loading } = useRequest((params) => dataService.toppingTypeCard({ prompt: params.id }), {
    manual: true,
    debounceWait: 500,
    onSuccess: (res, params) => {
      dispatch.app.update({
        chatPromptVersion: Date.now(),
      });
    },
    onError: (err) => feedback(err.data || "操作失败"),
    onFinally: (params, data) => {
      setActiVeItem((activeItem) => activeItem.filter((item) => item.id === params[0].id));
    },
  });

  const _topDataName = topData.map((item) => item.short_name);

  const newData = data.filter((item) => !_topDataName.includes(item.short_name));

  return (
    !isEmpty(newData) && (
      <div className={styles.questionTypeCard}>
        <List
          itemLayout="vertical"
          size="large"
          split={false}
          dataSource={newData}
          renderItem={(item) => (
            <List.Item
              key={item.short_name}
              className={`question-type-item-${item.id}`}
              onClick={() => onClick(item)}
            >
              <List.Item.Meta
                title={
                  <div
                    className="question-type-card-title"
                    title={item.short_name}
                  >
                    <div className="my-icon">
                      <PluginCardIcons type={item.short_name} />
                    </div>
                    <div className="short_name">{item.short_name}</div>
                  </div>
                }
                description={
                  <Typography.Paragraph
                    type="secondary"
                    ellipsis={{ rows: 4 }}
                    title={item.description}
                  >
                    {item.description}
                  </Typography.Paragraph>
                }
              />
              <div className="card-actions">
                <Tooltip
                  title="置顶"
                  placement="left"
                  getPopupContainer={(node) => document.body}
                  mouseEnterDelay={0.8}
                  mouseLeaveDelay={0}
                >
                  <Button
                    type="text"
                    icon={
                      loading || activeItem.includes(item.id) ? <LoadingOutlined /> : <PluginCardIcons type={`置顶`} />
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiVeItem((activeItem) => [...activeItem, item.id]);
                      topping(item);
                    }}
                  />
                </Tooltip>
              </div>
              <div className="footer">
                进入
                <SendOutlined />
              </div>
            </List.Item>
          )}
        />
      </div>
    )
  );
};

function GuessCard({ setText }) {
  const dispatch = useDispatch();
  const { middle } = useScreenSize();
  const { chatPromptGroup, chatPromptLoading } = useSelector((state) => state.app);
  const [activeKey, setActiveKey] = useState(localStorage.getItem("tabActiveKey") || "question-type-card-0");
  const { activeTabKey } = useSelector((state) => state.chat);

  const userPinnedChatPrompt = [];
  (chatPromptGroup || []).forEach((group) => {
    if (group && group.length > 1 && group[1] && group[1].length) {
      group[1].forEach((prompt) => {
        if (prompt.has_pin) {
          userPinnedChatPrompt.push(prompt);
        }
      });
    }
  });

  useEffect(() => {
    dispatch.chat.update({
      chatAtHome: true,
    });
    dispatch.chat.update({
      activeTabKey: localStorage.getItem("tabActiveKey"),
    });
    return () => {
      dispatch.chat.update({
        chatAtHome: false,
      });
    };
  }, []);

  useEffect(() => {
    !!activeTabKey && handleTabChange(activeTabKey);
  }, [activeTabKey]);

  const cardClickHandler = useCallback(
    (item) => {
      dispatch.chat.update({
        quickReply: item.short_name,
        replyVersion: Date.now(),
        placeHolderText: item.placeholder,
      });
    },
    [dispatch]
  );

  const handleTabChange = (key) => {
    setActiveKey(key);
    localStorage.setItem("tabActiveKey", key);
  };

  return (
    <Skeleton loading={chatPromptLoading && isEmpty(chatPromptGroup)}>
      <div style={{ width: "100%" }}>
        {!isEmpty(userPinnedChatPrompt) && (
          <TopCard
            data={userPinnedChatPrompt}
            onClick={cardClickHandler}
            middle={middle}
          />
        )}
        <Tabs
          style={{ marginTop: 12 }}
          // defaultActiveKey="1"
          activeKey={activeKey}
          onChange={handleTabChange}
          centered
          type="card"
          className={cls(styles.questionTypeTabs, "questionTypeTabs")}
          items={chatPromptGroup.map(([type, _data], index) => {
            return {
              label: (
                <div className={cls("tab-label", `tab-label-${index}`)}>
                  {middle && (
                    <div className="tab-icon">
                      <PluginCardIcons type={type} />
                    </div>
                  )}
                  <div className="tab-name">{middle ? type : type.slice(0, 2)}</div>
                </div>
              ),
              key: `question-type-card-${index}`,
              children: (
                <QuestionTypeCard
                  data={_data}
                  topData={userPinnedChatPrompt}
                  type={type}
                  onClick={cardClickHandler}
                  key={`question-type-card-${index}`}
                />
              ),
            };
          })}
        />
      </div>
    </Skeleton>
  );
}

export default GuessCard;
