import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMessages } from "@chatui/core";
import { v4 as uuidv4 } from "uuid";
import { findIndex, flattenDeep, includes, isObject } from "lodash-es";
import { useTrackedEffect } from "ahooks";
import { disableComposer, enableComposer, feedback, clearChatScrollBottom } from "@/utils/index";
import { QUESTION_ERROR } from "@/constants/writing";
import { MODEL_ICONS } from "@/constants/model";
import ChatBot from "./ChatBot";
import { data as dataService } from "@/services/data";
import styles from "./index.module.less";
import { newConfigKey } from "./key";

function ChatWindow() {
  const dispatch = useDispatch();
  const { messages, appendMsg, resetList } = useMessages([]);
  const { online, sessionId, quickReply, CNKI, Wanfang, Wiley } = useSelector((state) => state.chat);
  const { model, avatar = "/user.png" } = useSelector((state) => state.token);
  const { pubmedTypes, pubSearchStrategy, db } = useSelector((state) => state.config);
  const config = useSelector((state) => state.config);

  useTrackedEffect(
    (changes) => {
      changes?.forEach((changeIndex) => {
        const { url, title, downloaded } = [CNKI, Wanfang, Wiley][changeIndex];
        if (!downloaded) return;
        const scholarName = ["知网", "万方", "Wiley"][changeIndex];

        const question = `下载${scholarName}文献：${title}`;
        appendMsg({
          _id: uuidv4(),
          id: uuidv4(),
          type: "normal-message",
          content: { text: question, message_type: "text" },
          user: { avatar: avatar },
          position: "right",
          hasTime: false,
        });
        disableComposer();
        const send = async () => {
          try {
            const res = await dataService.ask({
              question: `下载${scholarName}文献：${title}`,
              config: {
                url,
                name: title,
              },
              session: sessionId,
              question_type: `${scholarName}下载`,
              model,
            });
            appendMsg({
              _id: uuidv4(),
              id: uuidv4(),
              type: "resolve-message",
              content: {
                messageId: res.data?.id,
                status: res.data?.status,
              },
              user: { avatar: MODEL_ICONS[model] },
              position: "left",
              hasTime: false,
            });
            dispatch.chat.updateLatestSessionId(sessionId);
            dispatch.chat.version();
          } catch (err) {
            let errorType = isObject(err.data) ? flattenDeep(Object.values(err.data))[0] : err.data;
            let errorIndex = findIndex(
              Object.keys(QUESTION_ERROR).map((err) => includes(errorType, err)),
              (err) => err
            );
            let messageType = Object.values(QUESTION_ERROR)[errorIndex];
            if (messageType === "just-notice") {
              feedback(errorType);
            } else
              appendMsg({
                _id: uuidv4(),
                id: uuidv4(),
                type: "normal-message",
                content: {
                  text: errorType,
                  message_type: messageType,
                  model,
                },
                user: { avatar: MODEL_ICONS[model] },
                position: "left",
                hasTime: false,
              });
            enableComposer();
          }
        };
        send();
        const ipt = document.querySelector(".Input");
        ipt.focus();
        dispatch.chat.updateWiley({ downloaded: false });
      });
    },
    [CNKI, Wanfang, Wiley]
  );

  // 页面重新加载时重置config
  useEffect(() => {
    dispatch.config.resetConfig();
  }, []);

  const sendHandler = useCallback(
    async (type, val) => {
      if (!sessionId) resetList([]);
      let newKey = newConfigKey(quickReply);
      if (type === "text" && val.trim()) {
        appendMsg({
          _id: uuidv4(),
          id: uuidv4(),
          type: "normal-message",
          content: { text: val, message_type: "text" },
          user: { avatar: avatar },
          position: "right",
          hasTime: false,
        });
        clearChatScrollBottom();
        disableComposer();
        try {
          const _sessionId = sessionId || uuidv4();
          const _config = config;
          if (newKey !== null) {
            for (let key in newKey) {
              newKey[key] = _config[key];
            }
          } else {
            newKey = online ? { online: true } : null;
          }
          const params = {
            question: val,
            session: _sessionId,
            question_type: quickReply,
            model,
            config: newKey,
          };
          const res = await dataService.ask(params);
          appendMsg({
            _id: uuidv4(),
            id: uuidv4(),
            type: "resolve-message",
            content: {
              messageId: res.data?.id,
              status: res.data?.status,
            },
            user: { avatar: MODEL_ICONS[model] },
            position: "left",
            hasTime: false,
          });
          if (!sessionId) {
            dispatch.chat.update({
              sessionId: _sessionId,
            });
          }
          dispatch.chat.updateLatestSessionId(_sessionId);
          dispatch.chat.version();
        } catch (err) {
          let errorType = isObject(err.data) ? flattenDeep(Object.values(err.data))[0] : err.data;
          let errorIndex = findIndex(
            Object.keys(QUESTION_ERROR).map((err) => includes(errorType, err)),
            (err) => err
          );
          // 402展示接口返回message, meassageType为no-times, 响应TimesErrorBubble
          let messageType = err.code === 402 ? "no-times" : Object.values(QUESTION_ERROR)[errorIndex];
          if (messageType === "just-notice") {
            feedback(errorType);
          } else
            appendMsg({
              _id: uuidv4(),
              id: uuidv4(),
              type: "normal-message",
              content: {
                text: err.code === 402 ? err.data : errorType, //返回402，text为err.data
                message_type: messageType,
                model,
                status: err.code === 403 || err.code === 402 ? 3 : null, //返回403, 402，status为3标红显示
              },
              user: { avatar: MODEL_ICONS[model] },
              position: "left",
              hasTime: false,
            });
          enableComposer();
        }
      } else if (type === "file") {
        const { text, fileList } = val;
        appendMsg({
          _id: uuidv4(),
          id: uuidv4(),
          type: "normal-message",
          content: {
            // text: { content: text, link: fileList },
            text: text,
            message_type: "file",
            config: {
              link: fileList,
            },
          },
          user: { avatar: avatar },
          position: "right",
          hasTime: false,
        });
        disableComposer();
        try {
          const _sessionId = sessionId || uuidv4();
          const linkList = fileList.map((file) => {
            return {
              url: file.response.data,
              name: file.name,
              isImage: file.isImage,
            };
          });
          const _config = config;
          if (newKey !== null) {
            for (let key in newKey) {
              newKey[key] = _config[key];
            }
          }
          const res = await dataService.ask({
            question: text,
            config: {
              link: linkList,
              ...newKey,
            },
            session: _sessionId,
            question_type: quickReply,
            model,
          });
          appendMsg({
            _id: uuidv4(),
            id: uuidv4(),
            type: "resolve-message",
            content: {
              messageId: res.data?.id,
              status: res.data?.status,
            },
            user: { avatar: MODEL_ICONS[model] },
            position: "left",
            hasTime: false,
          });
          if (!sessionId) {
            dispatch.chat.update({
              sessionId: _sessionId,
            });
          }
          dispatch.chat.updateLatestSessionId(_sessionId);
          dispatch.chat.version();
        } catch (err) {
          let errorType = isObject(err.data) ? flattenDeep(Object.values(err.data))[0] : err.data;
          let errorIndex = findIndex(
            Object.keys(QUESTION_ERROR).map((err) => includes(errorType, err)),
            (err) => err
          );
          let messageType = Object.values(QUESTION_ERROR)[errorIndex];
          if (messageType === "just-notice") {
            feedback(errorType);
          } else
            appendMsg({
              _id: uuidv4(),
              id: uuidv4(),
              type: "normal-message",
              content: {
                model,
                text: errorType,
                message_type: messageType,
                status: err.code === 403 ? 3 : null, //返回403，status为3标红显示
              },
              user: { avatar: MODEL_ICONS[model] },
              position: "left",
              hasTime: false,
            });
          enableComposer();
        }
      }
    },
    [appendMsg, sessionId, dispatch, model, resetList, quickReply, online, config]
  );

  return (
    <div className={styles.chatWindow}>
      <ChatBot
        messages={messages}
        appendMsg={appendMsg}
        resetList={resetList}
        sendHandler={sendHandler}
      />
    </div>
  );
}

export default ChatWindow;
