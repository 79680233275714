import { forwardRef, useCallback, useEffect, useState, useRef, useImperativeHandle } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
import cls from "classnames";
import { List, Pagination, Popover, Space, Tooltip, Button as AntdButton } from "antd";
import { SelectOutlined, SendOutlined } from "@ant-design/icons";
import { Button } from "@chatui/core";
import toggleClass from "@chatui/core/lib/utils/toggleClass";
import { ToolbarItem } from "@chatui/core/lib/components/Composer/ToolbarItem";
import TextSpinner from "@/components/common/TextSpinner";
import { useClickAway, useRequest } from "ahooks";
import { isEmpty } from "lodash-es";
import { data as dataService } from "@/services";
import { feedback } from "@/utils/index";
import useScreenSize from "@/hooks/useScreenSize";
import { ComposerInput } from "./ComposerInput";
import UploadButton from "./UploadButton";
import OutlineButton from "./OutlineEditor/OutlineButton";
import OnlineButton from "./OnlineButton";
import EvidenceButton from "./EvidenceEditor/EvidenceButton";
import RefineButton from "./PaperRefineEditor/RefineButton";
import DBSelect from "@/components/common/AdditionalConfig/DBSelect";
import AnalysisType from "@/components/common/AdditionalConfig/AnalysisType";
import LanguageSelect from "@/components/common/AdditionalConfig/LanguageSelect";
import KnowledgeSelect from "@/components/common/AdditionalConfig/KnowledgeSelect";
import styles from "./index.module.less";

const GptComposer = forwardRef((props, ref) => {
  let { text, onChange } = props;
  const { large, middle } = useScreenSize();
  const { language } = useSelector((state) => state.token);
  const { sessionId: chatId, quickReply } = useSelector((state) => state.chat);
  const { sessionId: documentId, paths } = useSelector((state) => state.document);

  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState();
  const [promptTypes, setPromptTypes] = useState([]);
  const [promptType, setPromptType] = useState("");
  const [prompts, setPrompts] = useState([]);
  const [open, setOpen] = useState(false);

  useClickAway(() => {
    setOpen(false);
  }, [document.querySelector(".Composer")]);

  useEffect(() => {
    setOpen(text.startsWith("/"));
    onChange(text);
  }, [text]);

  const { data, loading } = useRequest(
    () =>
      dataService.getPrompts({
        search: keyword,
        type: promptType,
        page: page,
        page_size: 5,
      }),
    {
      ready: text.startsWith("/"),
      refreshDeps: [page, keyword, promptType],
      throttleWait: 500,
      onSuccess: (res) => {
        setPrompts(res.data.results);
      },
      onError: (err) => {
        feedback(err.data);
      },
    }
  );
  useRequest(() => dataService.getPromptTypes(), {
    ready: text.startsWith("/"),
    throttleWait: 500,
    onSuccess: (res) => {
      const types = res.data?.map((d, index) => {
        return { label: d, value: d };
      });
      setPromptTypes(types);
    },
    onError: (err) => {
      feedback(err.data);
    },
  });

  const onKeywordChange = useCallback(
    (value) => {
      setPage(1);
      setKeyword(value);
    },
    [setKeyword, setPage]
  );

  const onPageChange = useCallback(
    (page) => {
      setPage(page);
    },
    [setPage]
  );

  //在切换session或者目录时使输入框聚焦,在小屏下不起作用
  useEffect(() => {
    if (!middle) return;
    const comIpt = document.querySelector(".Composer-input");
    comIpt.focus();
  }, [chatId, documentId, paths]);

  return (
    <Composer
      {...props}
      quickReply={quickReply}
      ref={ref}
      onKeywordChange={onKeywordChange}
    />
  );
});

GptComposer.displayName = "GptComposer";

export default GptComposer;

export const CLASS_NAME_FOCUSING = "S--focusing";

const ComposerCmpt = forwardRef((props, ref) => {
  const {
    text: initialText = "",
    textOnce: oTextOnce,
    inputType: initialInputType = "text",
    wideBreakpoint,
    placeholder: oPlaceholder = "请输入...",
    recorder = {},
    onInputTypeChange,
    onFocus,
    onBlur,
    onChange,
    onSend,
    onImageSend,
    onAccessoryToggle,
    toolbar = [],
    onToolbarClick,
    rightAction,
    inputOptions,
    quickReply,
    onKeywordChange,
  } = props;

  const [text, setText] = useState(initialText);
  const [textOnce, setTextOnce] = useState("");
  const [placeholder, setPlaceholder] = useState(oPlaceholder);
  const [accessoryContent, setAccessoryContent] = useState("");
  const inputRef = useRef(null);
  const focused = useRef(false);
  const blurTimer = useRef();
  const popoverTarget = useRef();
  const isMountRef = useRef(false);
  const [isWide, setWide] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [sendDisable, setSendDisable] = useState(true);

  const { replyVersion } = useSelector((state) => state.chat);
  const { sessionId: documentId, paths } = useSelector((state) => state.document);

  useEffect(() => {
    setFileList([]);
    if (!quickReply) setFileList([]);
  }, [replyVersion, quickReply]);

  useEffect(() => {
    const mq = wideBreakpoint && window.matchMedia ? window.matchMedia(`(min-width: ${wideBreakpoint})`) : false;

    function handleMq(e) {
      setWide(e.matches);
    }

    setWide(mq && mq.matches);

    if (mq) {
      mq.addListener(handleMq);
    }
    return () => {
      if (mq) {
        mq.removeListener(handleMq);
      }
    };
  }, [wideBreakpoint]);

  useEffect(() => {
    toggleClass("S--wide", isWide);
    if (!isWide) {
      setAccessoryContent("");
    }
  }, [isWide]);

  useEffect(() => {
    if (oTextOnce) {
      setTextOnce(oTextOnce);
      setPlaceholder(oTextOnce);
    } else {
      setTextOnce("");
      setPlaceholder(oPlaceholder);
    }
  }, [oPlaceholder, oTextOnce]);

  useEffect(() => {
    isMountRef.current = true;
  }, []);

  useImperativeHandle(ref, () => ({
    setText,
    setFileList,
  }));

  const handleInputFocus = useCallback(
    (e) => {
      clearTimeout(blurTimer.current);
      toggleClass(CLASS_NAME_FOCUSING, true);
      focused.current = true;

      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus]
  );

  const handleInputBlur = useCallback(
    (e) => {
      blurTimer.current = setTimeout(() => {
        toggleClass(CLASS_NAME_FOCUSING, false);
        focused.current = false;
      }, 0);

      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur]
  );

  const send = useCallback(() => {
    if (!isEmpty(fileList)) {
      onSend("file", { text, fileList });
      setText("");
      setFileList([]);
    } else if (text) {
      onSend("text", text);
      setText("");
    } else if (textOnce) {
      onSend("text", textOnce);
    }
    if (textOnce) {
      setTextOnce("");
      setPlaceholder(oPlaceholder);
    }
    if (focused.current) {
      inputRef.current.focus();
    }
  }, [oPlaceholder, onSend, text, textOnce, fileList]);

  const handleInputKeyDown = useCallback(
    (e) => {
      if (!e.shiftKey && e.keyCode === 13) {
        send();
        e.preventDefault();
      }
    },
    [send]
  );

  const handleTextChange = useCallback(
    (value, e) => {
      setText(value);
      onKeywordChange(value.replace("/", ""));
      if (onChange) {
        onChange(value, e);
      }
    },
    [onChange, onKeywordChange]
  );

  const onOutlineSend = useCallback(
    (text) => {
      if (!isEmpty(fileList)) {
        onSend("file", { text, fileList });
        setText("");
        setFileList([]);
      } else if (text) {
        onSend("text", text);
        setText("");
      }
      if (focused.current) {
        inputRef.current.focus();
      }
    },
    [send]
  );

  const onRefineSend = useCallback((text) => {
    onSend("text", text);
  });

  const handleSendBtnClick = useCallback(
    (e) => {
      send();
      e.preventDefault();
    },
    [send]
  );

  const handleToolbarClick = useCallback(
    (item, e) => {
      if (onToolbarClick) {
        onToolbarClick(item, e);
      }
      if (item.render) {
        popoverTarget.current = e.currentTarget;
        setAccessoryContent(item.render);
      }
    },
    [onToolbarClick]
  );

  const handlePopoverClose = useCallback(() => {
    setAccessoryContent("");
  }, []);

  const hasToolbar = toolbar.length > 0;

  const inputProps = {
    ...inputOptions,
    value: text,
    inputRef,
    placeholder,
    onFocus: handleInputFocus,
    onBlur: handleInputBlur,
    onKeyDown: handleInputKeyDown,
    onChange: handleTextChange,
    onImageSend,
  };

  let disableSendButton;
  useEffect(() => {
    disableSendButton = {
      语音转文字: sendDisable,
      数据分析: sendDisable,
      OFFICE密码破解: sendDisable,
      画图: sendDisable,
      OCR图文识别: sendDisable,
      图片解释: sendDisable,
      去背景: sendDisable,
      卡通化: sendDisable,
      名人访谈: sendDisable || !text,
    }[quickReply];
    if (disableSendButton === undefined) {
      disableSendButton = !text;
    }
  }, [text, sendDisable, quickReply]);

  // PC端
  if (isWide) {
    return (
      <div
        className={`${styles.Composer} Composer Composer--lg`}
        style={{ padding: "10px 0" }}
      >
        {hasToolbar &&
          toolbar.map((item) => (
            <ToolbarItem
              item={item}
              onClick={(e) => handleToolbarClick(item, e)}
              key={item.type}
            />
          ))}
        {accessoryContent && (
          <Popover
            active={!!accessoryContent}
            target={popoverTarget.current}
            onClose={handlePopoverClose}
          >
            {accessoryContent}
          </Popover>
        )}
        <div className="Composer-chatInput">
          <div className="Composer-inputWrap">
            <ComposerInput
              invisible={false}
              fileList={fileList}
              setFileList={setFileList}
              setSendDisable={setSendDisable}
              {...inputProps}
            />
            <span className={cls("Composer-spinner")}>
              <TextSpinner />
            </span>
          </div>
          <div className="Composer-custom-toolbar">
            <div className="Composer-custom-toolbar-items">
              {!quickReply && !documentId && isEmpty(paths) && <OnlineButton />}
              {!documentId && isEmpty(paths) && (
                <UploadButton
                  quickReply={quickReply}
                  setFileList={setFileList}
                  setSendDisable={setSendDisable}
                  fileList={fileList}
                  callback={(_fileList) => {
                    onSend("file", { text, fileList: _fileList });
                    setText("");
                    setFileList([]);
                  }}
                />
              )}
              <OutlineButton
                initialValue={text}
                quickReply={quickReply}
                onSend={onOutlineSend}
                setText={setText}
              ></OutlineButton>
              <EvidenceButton quickReply={quickReply}></EvidenceButton>
              <RefineButton
                initialValue={text}
                quickReply={quickReply}
                onSend={onRefineSend}
              ></RefineButton>
              <DBSelect quickReply={quickReply}></DBSelect>
              <AnalysisType quickReply={quickReply}></AnalysisType>
              <LanguageSelect quickReply={quickReply}></LanguageSelect>
              <KnowledgeSelect quickReply={quickReply}></KnowledgeSelect>
            </div>
            <div className="Composer-actions">
              <AntdButton
                className="Composer-sendBtn"
                disabled={disableSendButton}
                onMouseDown={handleSendBtnClick}
                type="primary"
                shape="round"
                icon={<SendOutlined />}
              ></AntdButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // 移动端
  return (
    <div className={`${styles.Composer} Composer`}>
      <div className="Composer-chatInput">
        <div className="Composer-inputWrap">
          <ComposerInput
            invisible={false}
            fileList={fileList}
            setFileList={setFileList}
            setSendDisable={setSendDisable}
            {...inputProps}
          />
          <span className={cls("Composer-spinner")}>
            <TextSpinner />
          </span>
        </div>
        <div className="Composer-custom-toolbar-mobile">
          <div>
            {!quickReply && !documentId && isEmpty(paths) && <OnlineButton />}
            {!documentId && isEmpty(paths) && (
              <UploadButton
                quickReply={quickReply}
                setFileList={setFileList}
                setSendDisable={setSendDisable}
                fileList={fileList}
                callback={(_fileList) => {
                  onSend("file", { text, fileList: _fileList });
                  setText("");
                  setFileList([]);
                }}
              />
            )}
            <OutlineButton
              initialValue={text}
              quickReply={quickReply}
              onSend={onOutlineSend}
            ></OutlineButton>
            <EvidenceButton quickReply={quickReply}></EvidenceButton>
            <DBSelect quickReply={quickReply}></DBSelect>
            <AnalysisType quickReply={quickReply}></AnalysisType>
            <LanguageSelect quickReply={quickReply}></LanguageSelect>
            <KnowledgeSelect quickReply={quickReply}></KnowledgeSelect>
          </div>
          {(text || textOnce) && (
            <div className="Composer-actions">
              <AntdButton
                className="Composer-sendBtn"
                disabled={disableSendButton}
                onMouseDown={handleSendBtnClick}
                type="primary"
                shape="round"
                icon={<SendOutlined />}
              ></AntdButton>
            </div>
          )}
        </div>
      </div>
      {
        // eslint-disable-next-line react/jsx-no-undef
        !text && rightAction && <Action {...rightAction} />
      }
    </div>
  );
});

ComposerCmpt.displayName = "Composer";

export const Composer = ComposerCmpt;
