import { Tooltip, Button as AntdButton, Upload, message } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash-es";
import styles from "./index.module.less";

function FileUpload({ setFileList, setSendDisable, fileList, callback }) {
  const handleChange = (info) => {
    const { fileList } = info;
    const newFileList = fileList
      .map((file) => {
        return {
          ...file,
          loading: !file.response && !file.uploadError,
          error: file.uploadError,
          isImage: file.type.indexOf("image/") > -1,
        };
      })
      .filter((file) => !file.error);
    const loading = !isEmpty(newFileList.filter((file) => file.loading));
    if (!loading && callback) {
      callback(newFileList);
    } else setFileList(newFileList);
    setSendDisable(loading);
  };

  const uploadImageProps = {
    accept: "*",
    action: "/api/usermedia/upload_media/",
    onChange: handleChange,
    multiple: true,
    beforeUpload: (file) => {
      const isImage = file.type.indexOf("image/") > -1;
      const size = isImage ? 3 : 10;
      const type = isImage ? "图片" : "文件";
      if (file.size > size * 1024 * 1024) {
        file.uploadError = true;
        message.error(`${file.name}${type}大小不能超过 ${size} MB`);
        return false;
      }
    },
    showUploadList: false,
  };

  return (
    <Tooltip
      placement="right"
      title="上传图表、文献、文档、方案、笔记、数据、代码等等，支持不超过10页的扫描版PDF，doc请另存为docx格式"
    >
      <Upload
        {...uploadImageProps}
        fileList={fileList}
        className={styles.customToolbarUpload}
      >
        <AntdButton
          type="text"
          icon={<CloudUploadOutlined />}
          size="large"
        />
      </Upload>
    </Tooltip>
  );
}

export default FileUpload;
