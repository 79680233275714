import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Space } from "antd";
import { CommentOutlined, FilePdfOutlined, FileWordOutlined, RollbackOutlined } from "@ant-design/icons";
import useScreenSize from "@/hooks/useScreenSize";
import WindowToolbar from "./WindowToolbar";
import ModelSegmented from "./ModelSegmented";
import EditableTitle from "./EditableTitle";
import HeadTongue from "./HeadTongue";
import styles from "./index.module.less";
import classNames from "classnames";

function WindowHeader({ showSider, siderHandler }) {
  const navigate = useNavigate();
  const { middle } = useScreenSize();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const navigateHandler = useCallback(
    async (to) => {
      switch (pathname) {
        case "/chat":
          navigate(to, { replace: true, show: true });
          dispatch.chat.resetSession();
          break;
        case "/document":
          navigate(to, { replace: true, show: true });
          dispatch.document.resetSession();
          break;
        default:
          break;
      }
      dispatch.user.resetCheck();
    },
    [dispatch, pathname]
  );

  return (
    <div className={styles.header}>
      <div
        className={classNames("head-right", {
          "full-width": !showSider,
          "zero-width": showSider,
        })}
      >
        <EditableTitle siderHandler={siderHandler} />
        {middle && <ModelSegmented />}
        <WindowToolbar />
      </div>
      {!middle && !showSider && (
        <div className="head-bottom">
          <HeadTongue />
        </div>
      )}
    </div>
  );
}

export default WindowHeader;
