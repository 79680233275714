const steps = [
  {
    target: ".tour-model",
    content: `系统提供所有主力模型：GPT, Claude, Google, 文心一言，力推GPT4, Opus, Sonnet 最聪明使用效果最好，GPT4o mini, 文心一言免费使用`,
    hideCloseButton: true,
    disableBeacon: true,
  },
  {
    target: ".user-avatar",
    content: "这里可以进入用户中心，设置头像绑定手机邮箱",
    hideCloseButton: true,
  },
  {
    target: ".tour-chat",
    content: "普通问答，只需发送一个问题，秒速得到想要的答案，在这里体验写一篇论文、解析一段代码、分析一个数据",
    hideCloseButton: true,
  },
  {
    target: ".tour-document",
    content: "文献阅读，上传文献，输入关心的问题，GPT帮您找到答案，或者全文翻译润色，也可以参考这些文献写一篇新论文",
    hideCloseButton: true,
  },
  {
    target: ".tab-label-0",
    content: "这里有我们为您准备的各式各样插件。科研版块，论文撰写文献综述、学术问答、翻译润色、方案报告心得撰写等",
    hideCloseButton: true,
    placement: "top",
    scrollDuration: 1000,
    disableScrolling: true, //禁用组件滚动,在callback控制滚动
  },
  {
    target: ".tab-label-1",
    content: "论文版块，知网、PubMed 论文库检索、下载",
    hideCloseButton: true,
    placement: "top",
    disableScrolling: true, //禁用组件滚动,在callback控制滚动
  },
  {
    target: ".tab-label-2",
    content: "工具版块，流程图绘制导出、表格整理，体验多种常用工具",
    hideCloseButton: true,
    placement: "top",
    disableScrolling: true, //禁用组件滚动,在callback控制滚动
  },
  {
    target: ".tab-label-3",
    content: "图像版块，图片解释，文字一键生图",
    hideCloseButton: true,
    disableScrolling: true, //禁用组件滚动,在callback控制滚动
  },
  {
    target: ".tab-label-4",
    content: "数据版块，数据分析可视化，一句话生成折线图、柱状图、饼状图",
    hideCloseButton: true,
    disableScrolling: true, //禁用组件滚动,在callback控制滚动
  },
  {
    target: ".tour-document",
    content: "请用鼠标点击此处，查看文献版块功能",
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: true,
    hideBackButton: true,
  },
];

export default steps;
