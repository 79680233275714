import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enableComposer, disableComposer } from "@/utils/index";
import { MESSAGE_TYPES } from "@/constants/message";
import NormalMessage from "./NormalMessage";

function ResolveMessage({ messageId, setComposerText, showQuestionType }) {
  const dispatch = useDispatch();
  const [finished, setFinished] = useState(false);
  const [createdAt, setCreatedAt] = useState();
  const [answerType, setAnswerType] = useState(0);
  const [status, setStatus] = useState(0);
  const [questionType, setQuestionType] = useState();
  const { quickReply } = useSelector((state) => state.chat);

  const {
    messageId: currentMessageId,
    messageValue,
    messageTyping,
    messageEndTime,
    messageType,
    messageText,
    messageErr,
  } = useSelector((state) => state.eventsource.messageMap[messageId] || {});

  useEffect(() => {
    if (!finished && currentMessageId === messageId) {
      if (messageErr) {
        setStatus(0);
        setFinished(true);
        if (quickReply !== "语音转文字") {
          enableComposer();
        }
        return;
      }
      if (messageEndTime) {
        if ([2, 3, 4, 5].includes(messageValue.status)) {
          setStatus(messageValue.status);
          setCreatedAt(messageValue.created_at);
          setAnswerType(messageValue.answer_type);
          setQuestionType(messageValue.question_type);
          setFinished(true);
          enableComposer();
          dispatch.user.getTokenInfo();
        } else disableComposer();
        if (quickReply === "语音转文字") {
          disableComposer();
        }
        return;
      }
      if (messageType === "delta") {
        setStatus(0);
      }
    }
  }, [finished, currentMessageId, messageTyping, messageValue, messageType, messageText, messageErr, messageEndTime]);

  if (messageText) {
    return (
      <NormalMessage
        messageType={MESSAGE_TYPES[answerType]}
        showQuestionType={showQuestionType}
        questionType={questionType}
        text={messageText.slice(0, messageTyping)}
        createdAt={createdAt}
        setComposerText={setComposerText}
        messageId={messageId}
        position={"left"}
        isLast={true}
        finished={finished}
        placeholderType={quickReply}
        status={status}
      />
    );
  } else
    return (
      <NormalMessage
        messageType={"message-typing"}
        position={"left"}
      />
    );
}

export default ResolveMessage;
