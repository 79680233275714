// url转base64
// Image对象转base64
function imageToBase64(image) {
  let canvas = document.createElement("canvas");
  let width = image.width;
  let height = image.height;
  canvas.width = width;
  canvas.height = height;
  let context = canvas.getContext("2d");
  context.drawImage(image, 0, 0, width, height);
  return canvas.toDataURL("image/png");
}

// 回调方式
function urlToBase64(url, callback = null) {
  let image = new Image();
  image.setAttribute("crossOrigin", "Anonymous");
  image.src = `${url}?v=${Math.random()}`;
  image.onload = function () {
    let dataURL = imageToBase64(image);
    if (callback) {
      callback(dataURL);
    }
  };
}

export function urlToBase64Async(url) {
  return new Promise((resolve, reject) => {
    urlToBase64(url, (data) => {
      resolve(data);
    });
  });
}

/**
 * 将svg导出成图片
 * @param node svg节点
 * @param name 生成的图片名称
 * @param width 生成的图片宽度
 * @param height 生成的图片高度
 * @param type 生成的图片类型
 */
export const svgToImage = (node, name, width, height, type = "png", background = false) => {
  let serializer = new XMLSerializer();
  let source = `<?xml version="1.0" standalone="no"?>\r\n${serializer.serializeToString(node)}`;
  let image = new Image();
  image.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(source)}`;
  let canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  let context = canvas.getContext("2d");
  if (background) {
    context.fillStyle = "#fff";
    context.fillRect(0, 0, width, height);
  }
  image.onload = function () {
    context.drawImage(image, 0, 0);
    let a = document.createElement("a");
    a.download = `${name}.${type}`;
    a.href = canvas.toDataURL(`image/${type}`);
    a.click();
  };
};

export function exportSVG(node, fileName) {
  const svgData = new XMLSerializer().serializeToString(node);
  const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
  const url = URL.createObjectURL(svgBlob);

  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();

  URL.revokeObjectURL(url);
}
