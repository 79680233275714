import { useState } from "react";
import styles from "./index.module.less";
import cls from "classnames";
import { Space } from "antd";
import ShareButton from "./ShareButton";
import ServiceButton from "./ServiceButton";
import VipButton from "./VipButton";
import MessageButton from "./MessageButton";
import ModeSwitch from "../../WindowHeader/WindowToolbar/ModeSwitch";
import SpecialButton from "./SpecialButton";

function LeftMenuBottom() {
  return (
    <div className={styles.LeftMenuBottom}>
      {/* <SpecialButton /> */}
      <VipButton />
      <MessageButton />
      <ShareButton />
      <ServiceButton />
      <ModeSwitch />
    </div>
  );
}

export default LeftMenuBottom;
