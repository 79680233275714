import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Space, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import cls from "classnames";
import { MODEL_ICONS } from "@/constants/model";

const modelTips = {
  GPT4_MINI: "响应速度快，每次普通对话消耗 1 点，登录后免费(不含文献)",
  CLAUDE_V3_OPUS: "Claude 最强模型，擅长解决文字和逻辑问题，每次普通对话消耗 30 点",
  CLAUDE_V3_SONNET: "Claude 最新模型，擅长代码等，每次普通对话消耗 10 点",
  CLAUDE_V3_HAIKU: "响应速度快，每次普通对话消耗 1 点",
  GPT4: "智能精准，效果极佳，每次普通对话消耗 20 点",
  ERNIE_TURBO: "中文支持好，回答速度快，登录后免费(不含文献)",
  GEMINI_PRO: "Google AI，回答速度快，擅长英文，每次普通对话消耗 10 点",
};

const modelList = [
  {
    label: "GPT4o mini",
    value: "GPT4_MINI",
  },
  {
    label: "GPT4",
    value: "GPT4",
  },
  {
    label: "Claude Haiku",
    value: "CLAUDE_V3_HAIKU",
  },
  {
    label: "Claude Sonnet",
    value: "CLAUDE_V3_SONNET",
  },
  {
    label: "Claude Opus",
    value: "CLAUDE_V3_OPUS",
  },
  {
    label: "文心一言",
    value: "ERNIE_TURBO",
  },
  {
    label: "Google Gemini",
    value: "GEMINI_PRO",
  },
];

function HeadTongue() {
  const dispatch = useDispatch();
  const { model = "GPT4" } = useSelector((state) => state.token);

  const handleMenuClick = ({ key }) => {
    dispatch.token.update({ model: key });
    message.info(modelTips[key]);
  };

  const items = modelList.map((_model) => {
    return {
      label: _model.label,
      key: _model.value,
      icon: (
        <img
          alt={_model.value}
          style={{ width: 32, height: 32 }}
          src={MODEL_ICONS[_model.value]}
        />
      ),
      className: cls({ active: model === _model.value }),
    };
  });

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown
      menu={menuProps}
      className="tongue"
      getPopupContainer={(node) => node}
    >
      <Space size="small">
        <div className="title">
          <img
            alt={model}
            src={MODEL_ICONS[model]}
          />
          {
            {
              GPT4_MINI: "GPT4o mini",
              CLAUDE_V3_OPUS: "Claude Opus",
              CLAUDE_V3_SONNET: "Claude Sonnet",
              GPT4: "GPT4",
              GEMINI_PRO: "Google Gemini",
              CLAUDE_V3_HAIKU: "Claude Haiku",
              ERNIE_TURBO: "文心一言",
            }[model]
          }
        </div>
        <DownOutlined />
      </Space>
    </Dropdown>
  );
}

export default HeadTongue;
