import { data as dataService } from "@/services";
import { feedback, setBubbleBgColor, setBubbleSelectable, enableComposer } from "@/utils/index";

export const user = {
  state: {
    tokenInfo: {},
    checking: false,
    checkedList: [],
    messageList: [],
    refeashMessage: false,
    userGuide: false,
  },
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    resetSession(state, payload) {
      return {
        ...state,
        tokenInfo: {},
      };
    },
    resetCheck(state, payload) {
      const { checkedList } = state;
      checkedList.forEach((id) => {
        setBubbleBgColor(id, false);
      });
      setBubbleSelectable(true);
      enableComposer();
      return {
        ...state,
        checking: false,
        checkedList: [],
      };
    },
  },
  effects: (dispatch) => ({
    async getTokenInfo(payload, rootState) {
      const { visitorId, hadLogin } = rootState.token;
      if (!visitorId) return;
      try {
        const res = await dataService.getToken(visitorId);
        dispatch.user.update({ tokenInfo: { ...res.data, hadLogin } });
      } catch (err) {
        feedback(err.data);
      } finally {
        // done nothing here
      }
      return "finish";
    },

    async checkUserGuide(payload, rootState) {
      try {
        const res = await dataService.newUserGuide("0c975faf-0db9-4b1d-838a-001a59b694f4");
        dispatch.user.update({ userGuide: !res.data });
      } catch (err) {
        feedback(err.data);
      } finally {
        // done nothing here
      }
    },
  }),
};
