import { Tooltip, Button as AntdButton, Upload, message } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash-es";
import styles from "./index.module.less";

function AudioUpload({ setFileList, setSendDisable, fileList, callback }) {
  const handleChange = (info) => {
    const { fileList } = info;
    const newFileList = fileList
      .map((file) => {
        return {
          ...file,
          loading: !file.response && !file.uploadError,
          error: file.uploadError,
        };
      })
      .filter((file) => !file.error);
    const loading = !isEmpty(newFileList.filter((file) => file.loading));
    if (!loading && callback) {
      callback(newFileList);
    } else setFileList(newFileList);
    setSendDisable(loading);
  };

  const uploadAudioProps = {
    accept: ".mp3,.mp4,.mpeg,.mpga,.m4a,.wav,.webm",
    action: "/api/usermedia/upload_media/",
    onChange: handleChange,
    multiple: true,
    beforeUpload: (file) => {
      if (file.size > 2 * 1024 * 1024) {
        file.uploadError = true;
        message.error(`${file.name}文件大小不能超过 2MB`);
        return false;
      }
    },
    showUploadList: false,
  };

  return (
    <Tooltip
      placement="right"
      title="上传音频"
    >
      <Upload
        {...uploadAudioProps}
        fileList={fileList}
        className={styles.customToolbarUpload}
      >
        <AntdButton
          type="text"
          icon={<CloudUploadOutlined />}
          size="large"
        />
      </Upload>
    </Tooltip>
  );
}

export default AudioUpload;
