import { useCallback } from "react";
import { Select, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useMatch } from "react-router-dom";
import styles from "../index.module.less";

function AnalysisType({ quickReply }) {
  const dispatch = useDispatch();
  const { analysisType } = useSelector((state) => state.config);
  const matchAtChat = useMatch("/chat");
  const options = [
    {
      label: "数据分析",
      value: "数据分析",
      tip: "分析计算，绘制折线图柱状图等",
    },
    {
      label: "数据探索",
      value: "数据探索",
      tip: "探索数据，得到数据的分析方法",
    },
  ];

  const component = (
    <div className={styles.selectorContainer}>
      <span className="selector-label">分析类型</span>
      <Select
        size="small"
        bordered={false}
        defaultValue={analysisType}
        onChange={(value) => {
          dispatch.config.update({
            analysisType: value,
          });
        }}
        style={{ width: "95px" }}
      >
        {options.map((item) => (
          <Select.Option
            key={item.value}
            value={item.value}
          >
            <Tooltip
              title={item.tip}
              placement="right"
            >
              {item.label}
            </Tooltip>
          </Select.Option>
        ))}
      </Select>
    </div>
  );

  if (matchAtChat) {
    switch (quickReply) {
      case "数据分析":
        return component;
    }
  }
}

export default AnalysisType;
