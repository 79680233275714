import { useCallback } from "react";
import { Button, Tooltip, message } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

function OnlineButton() {
  const dispatch = useDispatch();
  const { online } = useSelector((state) => state.chat);

  const onlineHandler = useCallback(() => {
    dispatch.chat.update({ online: !online });
  }, [online]);

  return (
    <Tooltip
      placement="left"
      title={
        online
          ? "关闭联网搜索"
          : "开启联网搜索，联网模式下无法使用上传文件功能，如需检索特定网站内容，请在问题最后添加 site:example.com"
      }
    >
      <Button
        size="large"
        type="text"
        icon={
          <GlobalOutlined
            spin={online}
            style={{ color: "var(--brand-1)" }}
          />
        }
        onClick={onlineHandler}
      />
    </Tooltip>
  );
}

export default OnlineButton;
