import { Button } from "antd";
import { AccountBookOutlined, UserOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";
import cls from "classnames";
import ShareButton from "./ShareButton";
import ServiceButton from "./ServiceButton";
import styles from "./index.module.less";
import useScreenSize from "@/hooks/useScreenSize";

function Sider({ className, children, showSider, showHandle = false, onHandleClick = () => {} }) {
  const { middle } = useScreenSize();
  return (
    <div className={cls(styles.sider, className, "sider")}>
      <div
        className="sider-body"
        style={{ width: !middle && showSider ? "97%" : "100%" }}
      >
        {children}
        {/* <div className="sider-footer">
          <Button
            href="/console/vip"
            icon={<UserOutlined />}
          >
            成为会员
          </Button>
          <ShareButton />
          <ServiceButton />
          <Button
            href="/userbenefit"
            target="_blank"
            icon={<AccountBookOutlined />}
          >
            会员权益
          </Button>
        </div> */}
        {showHandle ? (
          <div
            className="sider-toggle"
            onClick={onHandleClick}
          >
            {showSider ? <LeftOutlined /> : <RightOutlined />}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Sider;
