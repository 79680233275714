import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, message, Popconfirm } from "antd";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { useLockFn } from "ahooks";
import { feedback } from "@/utils/index";
import { data as dataService } from "@/services/data";
import { useNavigate } from "react-router-dom";

function RemoveButton({ id, afterRemove }) {
  const [removeLoading, setRemoveLoading] = useState(false);
  const dispatch = useDispatch();
  const { sessionId } = useSelector((state) => state.chat);
  const navigate = useNavigate();

  const confirmHandler = useLockFn(async (e) => {
    e.stopPropagation();
    setRemoveLoading(true);
    try {
      const res = await dataService.bulkRemoveSession({ ids: [id] });
      if (res.status === "OK") {
        dispatch.chat.version();
        afterRemove({ id });
        message.success("操作成功");
        if (sessionId === id) {
          dispatch.chat.resetSession();
          navigate("/chat");
        }
      }
    } catch (err) {
      setRemoveLoading(false);
      feedback(err.data);
    }
  });

  return (
    <Popconfirm
      title="确认删除对话？"
      okText="删除"
      cancelText="取消"
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      onConfirm={confirmHandler}
      onCancel={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      overlayStyle={{ width: "200px" }}
    >
      <Button
        size="small"
        icon={<DeleteOutlined />}
        type="text"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        block
        danger
      >
        删除
      </Button>
    </Popconfirm>
  );
}

export default RemoveButton;
