export default (messageType, text, questionType, position, status) => {
  let actionText = "";
  let actionList = [];
  let outlineActionList =
    status === 5
      ? [
          {
            label: "修改大纲",
            type: "edit-outline",
            icon: "edit",
          },
          {
            label: "继续撰写",
            type: "edit-stop",
            icon: "stop",
          },
        ]
      : [
          {
            label: "导出word",
            type: "export-word",
            icon: "download",
          },
        ];
  if (!text || !questionType || position !== "left" || !["text"].includes(messageType)) {
    return {
      text,
      actionType: messageType,
      actionText,
      actionList,
    };
  }
  switch (questionType) {
    case "文章优化":
    case "循证问答":
    case "普通问答":
    case "逐一问答":
      actionText = "";
      actionList = [
        {
          label: "导出word",
          type: "export-word",
          icon: "download",
        },
      ];
      break;
    case "论文撰写":
    case "文献综述":
    case "开题报告":
    case "长文撰写":
    case "文章重组":
    case "文章配图":
      actionText = status === 5 ? "请修改或确认大纲完成全文撰写" : "";
      actionList = outlineActionList;
      break;
    case "生成大纲":
      actionText = "立即使用大纲结果：";
      actionList = [
        {
          label: "论文撰写",
          path: "/chat",
          type: "switch-to-plugin",
          fill: true,
          icon: null,
        },
        {
          label: "文献综述",
          path: "/chat",
          type: "switch-to-plugin",
          fill: true,
          icon: null,
        },
        {
          label: "长文撰写",
          path: "/chat",
          type: "switch-to-plugin",
          fill: true,
          icon: null,
        },
      ];
      break;
  }
  return {
    text,
    actionType: messageType,
    actionText,
    actionList,
  };
};
