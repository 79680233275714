import styles from "./index.module.less";
import { Fragment, useState, useEffect, useCallback } from "react";
import ScrollToIcons from "@/icons/ScrollToIcons";

const ScrollToTop = ({ messagesRef }) => {
  const [showButton, setShowButton] = useState(false);
  const scrollHandler = (e) => {
    const target = document.querySelector(".PullToRefresh");
    if (target.scrollTop > 100) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    const target = document.querySelector(".PullToRefresh");
    target.addEventListener("scroll", scrollHandler);

    return () => {
      target.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const toBottomHandler = useCallback(() => {
    const target = document.querySelector(".PullToRefresh");
    target.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <Fragment>
      {showButton ? (
        <div
          className={styles.ScrollToTop}
          onClick={toBottomHandler}
        >
          <div className="scroll-to-top-content">
            <ScrollToIcons type="up" />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default ScrollToTop;
