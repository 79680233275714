import { Affix, Checkbox } from "antd";
import styles from "./index.module.less";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState } from "react";

function EvidenceSelectMenu() {
  const dispatch = useDispatch();
  const options = [
    { label: "Systematic Review & Meta-Analysis", value: "Systematic Review & Meta-Analysis" },
    { label: "Randomized Controlled Trial", value: "Randomized Controlled Trial" },
    { label: "Clinical Trial", value: "Clinical Trial" },
  ];
  const { config } = useSelector((state) => state.chat);

  const onChange = useCallback((checkedValues) => {
    dispatch.config.update({
      pubmedTypes: checkedValues,
    });
  });

  return (
    <Affix style={{ position: "absolute", top: 100, right: 12 }}>
      <div className={styles.evidenceSelectMenu}>
        <span className="menuTitle">PubMed分类</span>
        <Checkbox.Group
          options={options}
          onChange={onChange}
        />
      </div>
    </Affix>
  );
}
export default EvidenceSelectMenu;
