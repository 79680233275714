import { useCallback, useState } from "react";
import useScreenSize from "@/hooks/useScreenSize";
import { Button, Tooltip } from "antd";
import { useMatch } from "react-router-dom";
import { FormOutlined } from "@ant-design/icons";
import RefineModal from "./RefineModal";

function RefineButton({ quickReply, onSend, initialValue }) {
  const { middle } = useScreenSize();
  const matchAtChat = useMatch("/chat");
  const [open, setOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const openHandler = useCallback(() => {
    setOpen(true);
  }, []);

  const component = (
    <Tooltip
      placement="right"
      title={"输入原始文本和与之重复文本，GPT自动对比后降重"}
      open={openTooltip}
    >
      {middle ? (
        <Button
          type="text"
          className="Btn Btn--primary input-tools-btn"
          size="small"
          onClick={openHandler}
          onMouseEnter={() => setOpenTooltip(true)}
          onMouseLeave={() => setOpenTooltip(false)}
        >
          高级降重
        </Button>
      ) : (
        <Button
          size="large"
          type="text"
          icon={<FormOutlined style={{ color: "var(--brand-1)" }} />}
          onClick={openHandler}
          onMouseEnter={() => setOpenTooltip(true)}
          onMouseLeave={() => setOpenTooltip(false)}
        ></Button>
      )}
      <RefineModal
        open={open}
        setOpen={setOpen}
        onOk={onSend}
      />
    </Tooltip>
  );

  if (matchAtChat) {
    switch (quickReply) {
      case "论文降重":
        return component;
    }
  }
}

export default RefineButton;
