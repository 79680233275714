export const MODEL_TIPS = {
  GPT4_MINI: "响应速度快，每次普通对话消耗 1 点，登录后免费(不含文献)",
  CLAUDE_V3_OPUS: "Claude 最强模型，擅长解决文字和逻辑问题，每次普通对话消耗 30 点",
  CLAUDE_V3_SONNET: "Claude 最新模型，擅长代码等，每次普通对话消耗10点",
  CLAUDE_V3_HAIKU: "响应速度快，每次普通对话消耗 1 点",
  GPT4: "智能精准，效果极佳，每次普通对话消耗 20 点",
  ERNIE_TURBO: "中文支持好，回答速度快，登录后免费(不含文献)",
  GEMINI_PRO: "Google AI，回答速度快，每次普通对话消耗 10 点",
};

export const MODEL_LIST = [
  {
    label: "GPT4o mini",
    value: "GPT4_MINI",
  },
  {
    label: "GPT4",
    value: "GPT4",
  },
  {
    label: "Haiku",
    value: "CLAUDE_V3_HAIKU",
  },
  {
    label: "Sonnet",
    value: "CLAUDE_V3_SONNET",
  },
  {
    label: "Opus",
    value: "CLAUDE_V3_OPUS",
  },
  {
    label: "Gemini",
    value: "GEMINI_PRO",
  },
  {
    label: "文心一言",
    value: "ERNIE_TURBO",
  },
];

export const getModelName = (key) => {
  const item = MODEL_LIST.find((x) => x.value === key);
  return item && item.label ? item.label : key;
};

export const MODEL_ICONS = {
  GPT4_MINI: "/gpt35.png",
  CLAUDE_V3_HAIKU: "/haiku.jpeg",
  CLAUDE_V3_SONNET: "/sonnet.jpeg",
  CLAUDE_V3_OPUS: "/opus.jpeg",
  GPT4: "/gpt4.png",
  ERNIE_TURBO: "/ERNIE_TURBO.jpg",
  GEMINI_PRO: "/GEMINI.png",
};

export const MODEL_FEATURES = {
  GPT4_MINI: "GPT4o mini，适合解决简单问题，高要求或复杂问题请选择GPT4",
  CLAUDE_V3_OPUS: "Claude 3 Opus，能力比肩GPT4，速度更快，适合解决长文本问题。",
  CLAUDE_V3_SONNET: "Claude 3.5 Sonnet，Anthropic 最新模型，据说能力超过GPT4和Opus",
  CLAUDE_V3_HAIKU: "Claude 3 Haiku，响应快，创造力强。复杂问题请选择Sonnet，GPT4或Opus",
  GPT4: "GPT-4o，最强大模型，适合处理各类复杂问题，支持文字、图片",
  ERNIE_TURBO: "百度文心一言，具有更强的对话问答、内容创作生成等能力。适合简单中文问答，论文撰写等场景请选择GPT或Opus",
  GEMINI_PRO: "Gemini pro 1.5，Google最新出品超强人工智能大语言模型",
};
