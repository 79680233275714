import { useCallback, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHover } from "ahooks";
import { Button, Input, message, Checkbox } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import PluginCardIcons, { isIconExist } from "@/icons/PluginCardIcons/index";
import cls from "classnames";
import useScreenSize from "@/hooks/useScreenSize";
import { data as dataService } from "@/services";
import { feedback } from "@/utils/index";
import SessionItemAction from "../SessionItemAction";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

function ChatSessionItem({
  session,
  currentSession,
  siderHandler,
  afterRemove,
  afterRename,
  bulkStatus,
  bulkSelected,
  updateBulkSelected,
  setBulkByItemHandler,
}) {
  const { id, title } = session;
  const ref = useRef(null);
  const isShow = useHover(ref);
  const { middle } = useScreenSize();
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const [itemChecked, setItemChecked] = useState(bulkSelected.includes(id));
  const [value, setValue] = useState(title || "新会话");
  const navigate = useNavigate();

  const { sessionId } = useSelector((state) => state.chat);

  useEffect(() => {
    setItemChecked(bulkSelected.includes(id));
  }, [bulkSelected, bulkStatus]);

  const clickHandler = useCallback(() => {
    // 如果是批量选中模式
    if (bulkStatus) {
      updateBulkSelected(bulkSelected.includes(id) ? bulkSelected.filter((x) => x !== id) : [...bulkSelected, id]);
      return;
    }
    if (sessionId === id) return;
    navigate(`/chat?id=${id}`, { replace: true });
    dispatch.chat.update({
      sessionId: id,
      windowTitle: title,
      replyVersion: Date.now(),
      quickReply: session.scenes,
    });
    dispatch.user.update({ checking: false });
    if (!middle) {
      siderHandler(false);
    }
  }, [id, dispatch, siderHandler, title, session, sessionId, bulkStatus, bulkSelected]);

  const changeHandler = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const blurHandler = (e) => {
    setEditing(false);
    setValue(title);
  };

  const saveHandler = useCallback(async (value) => {
    const res = await dataService.updateSession(id, { title: value });
    if (res.status === "OK") {
      afterRename({
        id,
        title: value,
      });
      message.success("标题保存成功！");
      dispatch.chat.update({
        windowTitle: res.data.title,
        version: Date.now(),
      });
    } else {
      feedback(res.data);
    }
    setEditing(false);
  }, []);

  return (
    <div
      className={cls("session", {
        active: id === currentSession,
        selected: bulkSelected.includes(id),
        bulk: bulkStatus,
      })}
      key={`session-item-${id}`}
      onClick={clickHandler}
      ref={ref}
    >
      <div className="title-contain">
        {bulkStatus ? <Checkbox checked={itemChecked} /> : null}
        {isIconExist({ type: session.scenes }) ? (
          <span className="title-icon-border">
            <PluginCardIcons type={session.scenes} />
          </span>
        ) : (
          <span className="title-icon-border">
            <PluginCardIcons type="默认消息" />
          </span>
        )}
        {editing ? (
          <Search
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            autoFocus={true}
            bordered={false}
            value={value}
            onBlur={blurHandler}
            onChange={changeHandler}
            onSearch={saveHandler}
            placeholder="请输入标题"
            enterButton={
              <Button
                size="small"
                icon={<SaveOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {middle && "保存"}
              </Button>
            }
          />
        ) : (
          <div className="title">{title}</div>
        )}
        {(isShow || !middle) && !bulkStatus && !editing && (
          <SessionItemAction
            title={title}
            id={id}
            editing={editing}
            setEditing={setEditing}
            afterRemove={afterRemove}
            setBulkByItemHandler={setBulkByItemHandler}
          />
        )}
      </div>
      <div className="session-info">
        <div className={cls({ "session-scenes": !!session.scenes })}>{session.scenes}</div>
        <div className="session-create-at">{session.created_at}</div>
      </div>
    </div>
  );
}

export default ChatSessionItem;
