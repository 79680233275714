import styles from "./index.module.less";
import { Bubble } from "@chatui/core";

function TypingBubble() {
  return (
    <div className={styles.bubbleLeftWrapper}>
      <div
        className="bubble-container"
        style={{ flex: 1 }}
      >
        <Bubble className="typing-bubble Bubble text">
          <span className="typing-cursor"></span>
        </Bubble>
      </div>
    </div>
  );
}

export default TypingBubble;
