import { Fragment, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Image, Modal } from "antd";
import { WechatOutlined } from "@ant-design/icons";
import CustomerServiceDark from "@/assets/CustomerService-dark.jpg";
import CustomerServiceLight from "@/assets/CustomerService-light.jpg";
import styles from "./index.module.less";

function ServiceButton() {
  const [serviceOpen, setServiceOpen] = useState(false);
  const { dark } = useSelector((state) => state.theme);

  const onClick = (window.docGptOpenServiceModal = useCallback(() => {
    setServiceOpen(true);
  }, [setServiceOpen]));

  return (
    <Fragment>
      <Button
        onClick={onClick}
        icon={<WechatOutlined />}
      >
        客服
      </Button>
      <Modal
        title="客服微信"
        open={serviceOpen}
        onCancel={() => setServiceOpen(false)}
        footer={null}
        wrapClassName={styles.serviceModal}
      >
        <Image
          src={dark ? CustomerServiceDark : CustomerServiceLight}
          width={300}
          preview={false}
        />
      </Modal>
    </Fragment>
  );
}

export default ServiceButton;
