import { useCallback, useState, useEffect } from "react";
import cls from "classnames";
import FloatSider from "../FloatSider";
import useScreenSize from "@/hooks/useScreenSize";
import styles from "./index.module.less";
import { isElementCollision } from "@/utils/dom";

function TopMessage({ quickReply, prompts, tutorUrls = {}, faqUrls = {} }) {
  const { middle } = useScreenSize();
  const [collapse, setCollapse] = useState(localStorage.getItem("messageCollapse") || false);
  const [isCollapse, setIsCollapse] = useState(false); // 超过一行显示折叠按钮

  const handleCollapse = useCallback(() => {
    setCollapse(!collapse);
    localStorage.setItem("messageCollapse", collapse);
  }, [collapse]);

  useEffect(() => {
    let element = document.querySelector(".topMessage");
    if (!element) return;
    const result = isElementCollision(element, 1, {}, true);
    setIsCollapse(result);
  }, [quickReply]);

  return quickReply && prompts && prompts[quickReply] ? (
    <div className={cls(styles.topMessage, "topMessage")}>
      <div className={cls("inner", { collapse })}>
        <b>{quickReply}:</b>
        {`${prompts[quickReply]}`}
      </div>
      {isCollapse ? (
        <span
          className={cls("handler", { collapse })}
          onClick={handleCollapse}
        >
          <svg
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
          >
            <path
              d="M467.05836289 497.11504151a63.64133262 63.64133262 0 0 0 65.13877529 8.98465869 32.94374854 32.94374854 0 0 0 11.23082315-5.98977247l393.82753974-350.40168984A45.67201523 45.67201523 0 0 0 960.46586914 111.52343867a45.67201523 45.67201523 0 0 0-17.2205956-34.44119121 62.14388906 62.14388906 0 0 0-79.36448468 0L511.23293457 394.54018789 158.58508115 77.08224746A62.14388906 62.14388906 0 0 0 79.2205956 75.58480391a42.67712901 42.67712901 0 0 0 0 67.38494062z"
              fill=""
            ></path>
            <path
              d="M863.88078887 527.81262471L511.23293457 843.77312246 158.58508115 526.31518203a62.14388906 62.14388906 0 0 0-79.36448555 0 42.67712901 42.67712901 0 0 0 0 67.38493975l390.08393174 354.14529697a63.64133262 63.64133262 0 0 0 65.13877529 8.98465869 32.94374854 32.94374854 0 0 0 11.23082403-5.98977246l393.82753886-350.40168896a45.67201523 45.67201523 0 0 0 20.96420362-39.68224277 45.67201523 45.67201523 0 0 0-17.2205956-34.44119122 62.14388906 62.14388906 0 0 0-79.36448468 1.49744268z"
              fill=""
            ></path>
          </svg>
        </span>
      ) : null}
      {middle ? (
        <FloatSider
          quickReply={quickReply}
          tutorUrl={tutorUrls[quickReply]}
          faqUrl={faqUrls[quickReply]}
        />
      ) : null}
    </div>
  ) : (
    ""
  );
}

export default TopMessage;
