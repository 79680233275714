import { Fragment } from "react";
import { Button } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";

function ShareButton() {
  return (
    <Fragment>
      <Button
        href={"/console/affiliate"}
        icon={<ShareAltOutlined />}
      >
        分享 <sup> +30 </sup>
      </Button>
    </Fragment>
  );
}

export default ShareButton;
